.AsideDiscountCard {
	box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.3);
	background-color: #fff;
	padding: 21px 16px;
	border-radius: 3px;
	margin: 4px;
    min-height: 48px;
}

.AsideDiscountCard-content{
	min-width: 165px;
    max-width: 250px;
    position: relative;
}

.AsideDiscountCard-title{
	font-weight: bold;
	text-align: center;
}

.AsideDiscountCard-footer {
	display: flex;
	align-items: center;
    justify-content: flex-end;
}

.AsideDiscountCard-value{
	font-weight: bold;
	font-size: 25px;
	text-align: center;
	padding-top: 8px;
}

.AsideDiscountCard-number {
	color: #585858;
}

.AsideDiscountCard-footer img{
	width: 21px;
    height: 21px;
	padding-right: 4px;
}