.ColorList{
    flex: 1;
    display: flex;
    min-width: 500px; 
    border-radius: 5px;
	flex-direction: column;
	border: solid 1px #ccc;
}

@media only screen and (max-width: 1201px) {
    .ColorList {
        margin-top: 16px;
    }
}

.colorList-title{
    padding-bottom: 9px;
	border-bottom: solid 1px #ccc;
}

.ColorList-content{
    flex: 1;
    padding: 10px;
    flex-wrap: wrap;
    overflow-y: scroll;
    display: flex
}

.ColorList-content ul{
	display: flex;
    flex-wrap: wrap;
    flex: 1;
    align-content: flex-start;
    align-items: flex-start
}

.colorList-text{
    font-size: 14px;
 	padding-right: 4px;
    margin: 8px -10px 0px 12px;
}

.ColorListItem > div{
    flex: 1;
    display: flex;
    flex-direction: column
}

.ColorListItem > div >div{
    flex: 1;
    display: flex;
    align-items: center
}

.ColorPicker-border{
    padding: 16px 8px;
	border-right: solid 6px #2196f3;
}

.name-item-rule{
    font-weight: bold;
}

.hour-item-rule{
	font-size: 12px;
	margin-top: 6px;
    color: #757575;
}

.ColorList-popup{
    flex: 1;
    border: none;
    z-index: 999;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    min-height: 500px;
    border-radius: 5px;
    align-content: flex-start;
    align-items: flex-start
}

.ColorList-popup-title{
    font-weight: 700;
    margin: 16px 16px 0px 16px
}

.ColorListItem{
    flex: 1;
    margin: 4px;
    display: flex;
    padding: 12px;
    min-height: 45px;
    min-width: 132px;
    max-width: 143px;
    position: relative;
    border-radius: 3px;
    background-color:#f2f2f2;
    justify-content: space-between;
}

.ColorList .title{
    font-weight: 700;
    text-transform: capitalize
}

.ColorList .subtitle{
    color: #8a8a8a
}

.ColorList-popup li div{
	display: flex;
}

.ColorList-popup h2{
    padding: 4px;
    padding-left: 8px;
	font-weight: bold;
}

.link-fake {
    padding: 4px;
    cursor:pointer;
    margin-top: 16px;
    text-decoration:underline;
}

#color-list li:last-child{
	animation: bounce 0.5s
}

.ColorListItem .icon-bounce{
    transform: translate(12px, 12px)
}

.ColorListItem.disable{
    opacity: 0.5;
    user-select: none;
}

.emptyColorListMessage{
    flex: 1;
    color: #2196f3;
    text-align: center;
    border-radius: 5px;
    background-color: #d5edff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: dashed 1px #2196f3;
    padding: 8px
}

.emptyColorListMessage h1{
    font-weight: 700;
    font-size: 14px;
    padding: 4px
}

.emptyColorListMessage h2{
    font-size: 12px;
    font-weight: 100;
    padding: 4px
}