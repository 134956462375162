#see-more-indicator{
    width: 42px;
    height: 42px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 3px 3px 20px rgba(0,0,0,0.1);
    position: relative;
    animation: zoom-in 0.5s;
    user-select: none;


    .indicator{
        height: 16px;
        width: 16px;
        background-color: #2196f3;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 4px;
        position: absolute;
        top:-8px;
        right: -8px;
        font-size: 10px;
 

    }

    img{
        height: 100%;
        width: 100%;
        transform: translate(-1px);
    }
}

@keyframes zoom-in{
    0%{
        transform: scale(0);
    }
    100%{
        transform: scale(1);
    }
}