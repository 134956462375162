.register {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	z-index: 1;
	flex-direction: column
}

.steps{
	display: flex;
	align-items: center;
	border-bottom: 1px solid #CCCC;
	width: 100%;
}

.content-circles{
	margin: 16px;
	flex: 1;
}

.content-circles ul{
	display: flex;
}

.content-circles li{
	height: 39px;
    line-height: 39px;
	width: 39px;
	text-align: center;
	color: white;
	margin: 0 4px;
	border-radius: 100%
}

.form-register{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	border-left: 5px solid #2196f3;
	border-radius: 3px;
	height: 60%;
	width: 43%;
	box-shadow: 1px 1px 23px rgba(0,0,0,0.1);
	margin: .4px
}

.content-register{
	display: flex;
	justify-content: center;
	align-items: baseline;
	width: 95%;
}

.customer-form{
	display: flex;
	flex-wrap: wrap;
	flex: 1 1
}

.info-register {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
}

.info-register div {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.content-register label{
	width: 45%;
	margin: 7px;
}

.img-register{
	width: 120px;
	height: 120px;
}

.img-register img{
	width: 100%;
	height: 100%;
	box-shadow: 1px 1px 2px rgba(0,0,0,0.1);
}

.background-register {
	top: -60px;
	left: -30px;
	height: 50vh;
	width: 110vw;
	background-image: url('../../img/background.jpg');
	background-position: center;
	background-size: cover;
	position: absolute;
	transform: rotate(-4deg);
	z-index: -1;
}
.activeStep {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  text-align: center;
  line-height: 2em;
  align-items: baseline;
  background: dodgerblue;
  display: inline-block;
  color: white;
  position: relative;
}

.notPassedStep{
	background-color: lightblue;
	color: dodgerblue!important;
}

.passedStep{
	background-color: dodgerblue;
}

.activeStep {
  background: dodgerblue;
}

.buttons{
	display: flex;
	justify-content: space-between;
	padding-bottom: 16px;
	padding-top: 16px;
}
/* 
.select{
	background-color: #ffff;
	border-radius: 3px;
	border: solid 1px #ccc;
	margin-right: -7px
} */

@media (max-width: 1000px) {
	.form-register{
		width: 75%;
	}

	.steps{
		width: 75%;
	}
}

@media (max-width: 500px) {
	.form-register{
		width: 85%;
		height: auto;
		padding-top: 10px;
		margin-top: 20px;
		margin-bottom: 20px;
		padding-bottom: 10px;
	}

	.content-register {
		height: auto;
		width: 100%;
		flex-direction: column;
	}

	.info-register {
		width: 80%;
	}

	.content-register label {
		width: 90%;
	}

	.register{
		height: auto;
		padding-top: 15px; 
	}
}