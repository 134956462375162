.page-container{
    padding: 32px 16px;
    flex: 1;
    display: flex;
    flex-direction: column;

    .title-page {
        font-size: 21px;
        padding-bottom: 32px;
        font-weight: bold;
    }
    
    .flex-row{
        display: flex;
        flex-direction: row;
    }

    .space-between{
        justify-content: space-between;
    }

    .flex{
        flex: 1;
        display: flex;
        // flex-wrap: wrap;
    }

    .center{
        justify-content: center;
    }

    .column{
        flex-direction: column;
    }
}