#app-home-section{
    margin-right: 8px;
    background-color: rgba(255,255,255,0.6) ;
    border-radius: 5px;

    img{
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 5px;
        object-fit: cover;
    }

    h1{
        padding-top: 4px;
        padding-left: 4px;
    }
}

#app-home-section-container.loading-section{
    opacity: 0.2;
    transition: 500ms;

    .actions{
        display: none;
    }
}

#app-home-section-container{
    position: relative;

    .status{
        top: -3px;
        right: 6px;
        border-radius: 7.5;
        position: absolute;
    }

    .infos{
        display: flex;
        justify-content: flex-end;
        padding: 8px 0px;
        cursor: pointer;

        svg{
            margin-left: 4px;
        }

        svg:hover{
            transform: scale(1.1);
            transform-origin: center;
            transition: 0.5s
        }

        svg:active{
            transform: scale(0.8);
            transform-origin: center;
        }
    }

    a{
        text-align: center;
        padding: 8px 4px;
    }

    a:visited, a:link{
        color: #2196f3;
    }

    a:hover{
        color: #0b68b4;
    }
}
