
.list-itensProduct{
	display: flex;
	flex-wrap: wrap;
    box-sizing: border-box;
	flex: 1;
	position: relative;
	align-content: start;
}

.itens-row{
	display: flex;
	flex: 1;
	align-items: flex-end;
	justify-content: flex-end;
}

.option-itens{
	display: flex;
	justify-items: center;
	justify-content: space-between;
    align-items: center;
}

.btn{
	height: 40px;
	width: 100px;
	margin-left: 20px;
	background-color: #fff;
	color: #1976d2;
	border: 1px solid #1976d2;
}

.newItem{
	background-color: #1976d2;
	color: #FFF;
	border: 1px solid #1976d2;
}

.delete{
	background-color: #fff;
	color: #1976d2;
	border: 1px solid #1976d2;
}

.options-bar{
	display: flex;
    justify-content: space-between;
    border-bottom: solid 1px #f1f1f1;
	height: 42px;
	padding: 16px 23px;
	position: relative;
}

.itens-content{
    flex: 1;
	display: flex;
	flex-direction: column;
	position: relative;
}

