.section {
	display: flex;
	height: 100%;
	flex: 1
}


@media (max-width: 650px) {
	.section {
    	flex-direction: column;
	}
}