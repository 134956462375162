.asideListCard {
	box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.3) ;
	background-color: #fff;
	padding: 16px;
	border-radius: 3px;
	margin: 4px;
	min-width: 150px;
	display: flex;
	align-items: center;
	word-break: break-all;
}

.scrollVertical{
	display: flex;
	flex-direction: column;
}

.asideListCard-item p:first-child, .asideListCard-table p:first-child {
	font-weight: 700;
}

.asideListCard-item div{
	padding: 4px 0px;
}

.asideListCard-item p:last-child {
	padding-top: 4px;
}

.asideListCard-table p:first-child {
	padding-right: 8px;
}

.asideListCard-table{
	display: flex;
	align-items: center;
	margin: 4px;
	padding: 4px;
	border-radius: 3px;
}

.asideListCard-table:nth-child(odd){
	background-color: #E2E2E2;
}

.asideListCard-title {
	font-weight: bold;
	padding-bottom: 8px
}

.asideListCard .link {
	color: #2196f3;
}