.dragged > .selectableItem {
    opacity: 0.1;
    border-top: 1px solid #999999;
    border-bottom: 1px solid #ffffff;
}
  
.floating > .selectableItem {
    background-color: #ffffff!important;
    box-shadow: 3px 3px 20px rgba(0,0,0,0.2)!important;
}

.dl-list{
    display: flex!important;
    flex-wrap: wrap;
}

.app-manager-card{
    width: calc(100vw - 700px);
    margin-right: 120px;
}