.form-personalData {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 31px;
	flex-direction: column
}

.form-personalData p{
	padding-top: 20px;
	font-weight: 700;
	font-size: 14px
}

.form-personalData .title{
	font-weight: 700;
}

.personalData label.label-textarea {
	flex: 1;
	margin-left: 46px;
	margin-right: 46px
}

.btnSave{
	width: 180px;
	float: right;
	margin-right: 40px;
	margin-top: 18px
}

.form-firstRow{
	display: flex;
	flex: 1;
	flex-direction: row;
}

.form-firstRow input{
	width: 235px
}

.form-row{
	display: flex;
	flex: 1;
	flex-direction: row
}

.form-row select{
	width: 170px
}

.personalData-CPF{
	width: 165px
}

.personalData-surname{
	width: 180px
}