.promoapp-home{
    display: flex;
    flex-direction: column;
    height: 100%;

    header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        
        h1{
            font-weight: 700;
            font-size: 16px;
        }

        svg{
            height: 21px;
        }
    }

    #app-home-section{
        animation: easeInAnimationY 500ms linear;
    }

    footer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        border-top: 1px solid #eaeaea;

        div{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        h1{
            font-size: 10px;
            color: #8a8a8a;
            padding-top: 4px;
        }

        svg{
            stroke: #8a8a8a;
        }

        .active > svg, .active > h1{
            color: #000;
            stroke: #000;
        }
    }

    .banner{
        height: 190px;
        width: 100%;
        user-select: none;
        background-color: #f2f2f2;

        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            transition: 500ms;
        }
    }

    main{
        overflow: scroll;
        flex: 1;
        padding-bottom: 16px;
    }

    h2{
        font-weight: 700;
        padding: 16px;
    }

    #highlighted-coupon-container{
        border: none;
        box-shadow: 0px 3px 15px rgba(0,0,0,0.1);
        margin-bottom: 16px;
        margin-top:  8px;

        h2{
            padding: 4px 0;
        }

        img{
            height: 180px;
            width: 180px;
        }
    }

    section{
        display: flex;
        padding: 0px 16px;
        overflow-x: scroll;
        flex: 1;
        box-sizing: border-box;

        .square{
            height: 100px;
            min-width: 100px;
            background-color: #f2f2f2;
            border-radius: 5px;
            margin-right: 5px;
        }

        .rect{
            height: 160px;
            min-width: 100%;
            background-color: #f2f2f2;
            border-radius: 5px;
            margin-right: 8px;
        }
    }
}