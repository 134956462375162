%center{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.emptyMessage{
    flex: 1;
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: #8a8a8a;
    user-select: none;
    pointer-events: none;
	text-align: center
}

.emptyMessage h1{
    font-weight: 700;
    font-size: 20px;
    margin: 8px 16px 4px 16px
}

.emptyMessage h2{
    font-size: 18px;
	padding: 0 16px
}

// .emptyMessage svg{
//     width: 60px;
//     height: 60px;
// }

.ColorEmptyMessage{
    @extend %center;
    color: #2196f3;
    text-align: center;
    border-radius: 5px;
    background-color: #d5edff;
    border: dashed 1px #2196f3;
    user-select: none;
    flex: 1;
    padding: 16px;
    padding-bottom: 32px;

    svg, path, circle{
        // fill: #2196f3;
        margin: 16px;
    }

    h1{
        padding: 8px!important;
        font-weight: 700;
        font-size: 1.2em
    }
}