.smallCard {
	cursor: pointer;
	background-color: #FFF;
	box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.3);
	display: flex;
	align-items: center;
	border-radius: 3px;
	flex: 1;
	height: 125px;
	word-break: break-all;
	min-width: 200px;
	margin: 8px;
	padding: 8px;
	position: relative;
	transition: 0.3s;
}

.smallCard:hover{
	box-shadow: 0px 0px 15px #2196f360
}

.smallCard:active{
	transition: 0.3s;
	box-shadow: none
}

.content-smallCard {
	display: flex;
	height: 100%;
	width: 100%;
	padding-right: 16px;
	flex-direction: column;
	justify-content: space-between;
}

.header-smallCard {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
	margin-right: 10px;
	max-height: 90px;
	min-height: 90px;
	min-width: 90px;
	max-width: 90px;
	border-radius: 100%;
	overflow: hidden;
	
}

.img-profile {
	width: 100%;
	height: 100%;
	min-width: 92px;
	min-height: 92px;
	object-fit: cover;
	image-rendering: optimizeQuality;}

.img-profile.loading{
	display: none;
}

.name-logo{
	width: 100%;
	height: 100%;
	min-width: 112px;
	min-height: 112px;
	color: #2196f3;
	background-color: #d5edff;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: capitalize
}

.name-logo h1{
	font-size: 26px;
}

.title-smallCard {
	font-weight: bold;
	padding-top: 10px;
	text-transform: capitalize
}

.email-smallCard {
	color: #2196f3;
	padding-bottom: 5px;
	flex-wrap: wrap;
}

.footer-smallCard{
	color: #8d8d8d;
	padding: 8px 0;
}

.smallCard .status{
    height: 12px;
    width: 12px;
    border-radius: 12px;
    position: absolute;
    top: 12px;
    left: 12px
}

.smallCard .status.DEPRECATED, .smallCard .status.EXPIRED{
    background-color: #ff5f58;
    box-shadow: 0px 0px 15px #ff5f58
}

.smallCard .status.PUBLISHED, .smallCard .status.ENABLE{
	background-color: #2ac940;
    box-shadow: 0px 0px 15px #2ac940
}

.smallCard .status.PENDING{
	background-color: #ffba01;
    box-shadow: 0px 0px 15px #ffba01
}