.rankingCard {
	display: flex;
	border-radius: 3px;
	min-width: 230px;
	margin: 4px;
	min-height: 300px;
	position: relative;
	flex: 1;
	
}

.rankingCard.shadow{
	box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.3);
	background-color: #FFF;
}

.rankingCard.border{
	border: solid 2px #eee
}

.rankingCard .tip{
	background-color: #f2f2f2;
	text-align: center;
	padding: 16px;
	position: absolute;
	right: 0px;
	top: 0px;
	left: 0;
	bottom: 0;
	z-index: 9999;
	color: #6d6d6d;
	font-weight: 700;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	transition: 0.3s
}

.rankingCard-list{
	display: flex;
	flex: 1;
	align-items: baseline;
	width: 100%;
	justify-content: space-between
}

.rankingCard-list ul{
	flex: 1;
}

.rankingCard-list li{
	display: flex;
	flex: 1;
	padding: 8px;
	position: relative;
	justify-content: center;
	align-items: center;
}

.rankingCard-list ul li:nth-child(odd){
	border-bottom:solid 1px #f2f2f2;
	border-top:solid 1px #f2f2f2;
}


.rankingCard-list li:hover .tip{
	opacity: 1;
	transition: 0.3s
}

.ranking-things{
	display: flex
}

.rankingCard-item{
	display: flex;
	flex-direction: column;
	padding: 0 5px 0 5px;
	font-size: 12px;
	flex: 1;
	justify-content: center;
	padding: 0 16px;
}

.rankingCard-item h1{
	font-weight: bold;
}
.rankingCard-item h2{
	color: #babaca;
	font-weight: bold;
}

.rankingCard-icons-align{
	display: flex;
	flex-direction: column;
}

.rankingCard-img-align{
	display: flex;
	align-items: center;
}

.rankingCard-img-align img{
	padding-right: 4px
}

.ranking-things p{
	height: 39px;
    line-height: 39px;
	width: 39px;
	text-align: center;
	color: dodgerblue;
	font-weight: bold;
	background-color: #C3DEF8;
	border-radius: 100%
}