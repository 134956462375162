.selectableItem{
	margin: 16px;
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    border-radius: 5px;
    user-select: none;
    position: relative;
    padding: 16px;
    /* box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.3); */
    /* border: 1px #ccc solid;
    border-radius: 5px; */
    border-radius: 8px;
}

.body-itens{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.selectableItem .img{
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	width: 90px;
	height: 90px;
	border-radius: 100%;
    border: 1px solid #FFF;
/*	border: 3px solid #2196f3;*/
	/* position: relative; */
	cursor: pointer;
    background-color: #fff;
    padding: 4px;
    box-sizing: border-box;
}

.selectableItem .img.selected{
    border-color: #2196f3;

}

.img.selected-cant-unselected img{
    -webkit-filter: saturate(0);
    cursor: not-allowed;
}


.img.selected-cant-unselected{
    border-color: #babaca;
    background-color: #f2f2f2!important
}

.img.selected-cant-unselected > .name-logo{
    color: #babaca;
    background-color: #f2f2f2!important
}

.loading{
	display: none;
}

.selectableItem .img img {
	width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover
}

.selectableItem .tip{
	background-color: #2196f3;
    color: #fff;
    padding: 4px;
	border-radius: 5px;
	text-align: center;
    margin: 4px;
    margin-top: 12px;
}

.selectableItem .tip p {
    text-align: center;
    font-size: 12px;
    
}

.selectableItem .tip:hover ::after {
    padding: 4px;
    border-radius: 5px;
    background-color: #000;
    position: absolute;
    right: -36px;
    top: 2px;
    opacity: 0.4;
}


.selectableItem .description {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
    text-align: center;
    user-select: text
}

.selectableItem .description h2{
	font-weight: bold;
    padding: 8px 0;
    text-align: center;
    font-size: 14px;
}

.selectableItem .description p{
	color: #8a8a8a
}

.selectableItem .actions{
    margin-top: 16px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: 25px;
}
.selectableItem .upper-actions{
    position: absolute;
    top: -16px
}

.selectableItem .actions > svg:hover{
    transform: scale(1.1);
    transform-origin: center;
    transition: 0.5s
}

.selectableItem .actions > svg{
    margin-right: 4px;
}

.selectableItem .interest{
    color: #2196f3!important;
    padding-top: 8px;
}

.indent {
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
}