.asideProduct {
	display: flex;
	flex-direction: column;
	padding: 10px;
	min-width: 200px;
	width: 250px;
	overflow: auto;
}


.asideCard1, .asideCard2{
	margin: 3px;
	max-height: 200px;
}


@media (max-width: 650px) {
	.asideProduct {
		height: 180px;
		display: flex;
		flex-direction: row;
		width: 100%
	}
}

