.tag-select-container{
    flex: 1;
    position: relative;
    display: contents;

    .options-container{
        background-color: #fff;
        border-radius: 3px;
        transition: 0.5s;
        box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.3);
        user-select: none;
        margin-top: 4px;
        overflow: scroll;
        padding: 8px;

        .tag-option{
            padding: 8px;
            margin-top: 4px;
            user-select: none;
        }

        .tag-option:hover{
            background-color: #f2f2f2;
            border-radius: 3px;
        }

        .section-title{
            color: #aeaeae;
            padding: 4px 0px;
        }
    }
}

.tag-select-content{
    border: 1px solid #ccc;
    padding: 8px;
    background-color: #FFF;
    border-radius: 3px;
    align-items: center;
    flex-wrap: wrap;
    user-select: none;
    display: flex;

    .selected-tag{
        background-color: #f2f2f2;
        padding: 8px;
        border-radius: 3px;
        color: #ccc;
        color: rgba(0,0,0,.65);
        font-size: 14px;
        margin-right: 2px;
        margin-bottom: 2px;
        display: flex;
        font-size: 12px;

        h1{
            font-weight: 700;
        }
    }

    .selected-tag .delete-action{
        content: 'x';
        display: flex;
        top: 4px;
        right: 4px;
        padding-left: 16px; 
        margin-right: -8px;
        margin-top: -8px;
        cursor: pointer;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-right: 8px;
        margin-bottom: -8px;
        // position: absolute;
    }

    input{
        margin: 0;
        border: none;
        flex: 1;
        min-width: 100px;
    }
}


.tag-option-container{
    font-size: 12px;
    display: flex;
    align-items: center;
    h1{
        font-weight: 700;
    }
}