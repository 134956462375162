.statistic{
	position: relative;
	flex: 1
}

.statistic .content{
	display: flex;
	justify-content: space-between;
	padding: 12px;
	flex-wrap: wrap;
}