.avatar-pic {
	border-radius: 100%;
	overflow: hidden;
	min-height: 80px;
	min-width: 80px;
	max-width: 80px;
	max-height: 80px;
	align-self: center;
	border: 5px solid #fff;
	background-color: #FFF;
	box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.3);
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.name-logo{
	h1{
		font-weight: 100!important;
	}
}

.background-picture.small{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	transform: scale(0.4);
}


.avatar-pic img {
	width: 100%;
	height: 100%;
}

.avatar-pic .edit-picture{
	position: absolute;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.6);
	color:rgba(255, 255, 255, 0.8);
	width: 100%;
	text-align: center;
	padding: 8px 0;
	font-size: 12px;
	display: none;
	cursor: pointer;
}

.avatar-pic:hover .edit-picture{
	display: inline
}

.edit-picture:hover {
	color: #FFF
}