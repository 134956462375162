.card {
   box-shadow:  2px 2px 11px rgba(171, 174, 185, 0.3);
   background-color: #fff;
   position: relative;
   padding: 16px;
   border-radius: 3px;
   margin: 4px;
}

.card .title{
    font-weight: 700;
    padding-bottom: 4px
}

.card .text{
    padding-bottom: 8px
}

.aside-button-card{
    display: flex;
    flex-direction: column;
}

.aside-button-card > a{
    color: #2196f3;
    align-self: center;
    padding: 16px 0px 8px 0px;
    transition: 0.5s;
}

.aside-button-card > a:hover{
    color: #2196f3;
}

.aside-button-card > a:active{
    transform: scale(0.8);
    transition: 0.5s
}