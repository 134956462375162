.home{
	flex: 1;
	display: flex;
}

.home-row{
	display: flex;
	flex-direction: row;
	flex: 1;
}

.home-column{
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 16px;
	flex-wrap: wrap;
}

.operator-row{
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
}

.operator-column{
	display: flex;
	flex: 1;
	flex-direction: column;
	user-select: none;
}