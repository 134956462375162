.row{
    display: flex;
    align-items: center
}

.toggle-text{
    padding-right: 4px;
    font-size: 12px
}

.toggle{
  height: 36px;
  width: 76px;
  background-Color: #ccc;
  display: flex;
  justify-content: space-around;
  align-Items: center;
  color: #FFF;
  border-radius: 42px;
  position: relative;
  user-select: none;
  transition: 0.5s;
}

.toggle.small{
    height: 22px;
    width: 43px;
    border-radius: 22px;
}

.toggle.small .switcher{
    height: 18px;
    width: 18px;
    left: 2px
}


.toggle.small.right .switcher{
  left: 23px;
  box-shadow: -3px 1px 5px rgba(0,0,0,0.2);
  transition: 0.5s;
  transition-timing-function: steps-start;
}

.toggle .switcher{
  height: 29px;
  width: 29px;
  background-color: #FFF;
  border-radius: 42px;
  position: absolute;
  left: 4px;
  box-shadow: 3px 1px 5px rgba(0,0,0,0.3);
  transition: 0.5s;
  transition-timing-function: steps-start;
}

.toggle.right .switcher{
  left: 43px;
  box-shadow: -3px 1px 5px rgba(0,0,0,0.2);
  transition: 0.5s;
  transition-timing-function: steps-start;
}

.toggle.right{
    background-Color: #2196f3;
    transition: 0.5s;
}

.toggle h1{
  z-index: 99;
}

.toggle.right h1:nth-child(2), .toggle.left h1:nth-child(1){
  color: #2196f3;
  transition: 0.5s;
  font-weight: 700
}

.square-toggle{
	width: 50%;
	position: absolute;
	height: inherit;
	display: flex;
	box-sizing: border-box;
}

.square-toggle p{
	margin: auto;
}

.square-toggle:nth-child(1) {
	right: 0;
}

.square-inner-container {
	position: absolute;
	left: 0;
	top: 0;
	width: inherit;
	height: inherit;
	text-transform: uppercase;
	font-size: .6em;
	letter-spacing: .2em;
}

.square-inner-container:first-child {
	color: #8a8a8a;
}

.square-inner-container:nth-child(2){
	background: dodgerblue;
	color: white;
	clip-path: inset(0 50% 0 0);
	transition: .3s cubic-bezier(0,0,0,1);
}

.square-container {
	width: 90px;
	height: 38px;
	margin: auto;
	position: relative;
	border-radius: 3px;
	overflow: hidden;
	user-select: none;
  cursor: pointer;
  border: solid 1px #CCC
}

.moving-square{
	height: 100%;
	width: 50%;
	background-color: #2196f3;
	position: absolute;
  transition: 500ms;
  box-shadow: 2px 0px 5px rgba(0,0,0,0.2)
}

.right .moving-square{
	transform: translateX(100%);
  transition: 500ms;
  box-shadow: -2px 0px 5px rgba(0,0,0,0.2)
}

.square-container p{
	z-index: 2;
	font-size: 12px;
	font-weight: 700
}

.white{
	color: #FFF;
	transition: 500ms;
	font-size: 14px
}