.cardTab-container.reports{
    padding: 18px;

    label{
        margin-bottom: 0px;
        padding: 0;
    }
    .search-report{
        display: flex;
        justify-content: space-between;
        padding: 16px 0px
    }

    .normal-button{
        margin: 0;
        padding: 4px 16px;
        align-self: flex-end
    }

    .options{
        display: flex;
        align-items: center;
    }
    
} 
.download-button{
    color: #2196f3;
    background-color: #d5edff;
    padding: 8px;
    border-radius: 5px;
    transition: 0.5s;
    margin: 8px 0px;
}

.page{
    color: #2196f3;
    padding: 10px;
    transition: 0.5s;
    text-decoration: underline #d5edff;
    margin: 8px 0px;
}

.download-button:hover{
    color: #d5edff;
    background-color: #2196f3
}

.download-button:active{
    transform: scale(0.8);
    transition: 0.5s;
}