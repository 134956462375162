.product-item-range {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;

    .title{
        font-weight: 700;
    }

    .subtitle{
        color: #8a8a8a;
        font-weight: 400;
        margin-bottom: 8px;
        padding: 0;
    }

    .ranges-container{
        label{
            display: flex;
            flex-direction: row;
            align-items: center;

            input{
                margin-right: 8px;
            }
        }
    }
    
}