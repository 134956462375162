.search-container{
    padding: 16px;
    flex: 1;
    display: flex;
    max-height: 100vh;

    .filter-container{
        position: -webkit-sticky; 
        // position: sticky!important;
        top: 0;

    }

    main{
        flex: 1;
        position: relative;
        display: grid;
        align-content: flex-start;
    }

    @media (min-width: 980px)  {
        main{
            grid-template-columns: 1fr 1fr
        }
    }

    @media (min-width: 1290px)  {
        main{
            grid-template-columns: 1fr 1fr 1fr
        }
    }

    @media (min-width: 1390px)  {
        main{
            grid-template-columns: 1fr 1fr 1fr 1fr
        }
    }

    header{
        padding: 8px;

        nav{
            display: flex;
            align-items: center;

            input{
                background-color: #fff;
                border: none;
                box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.3);
                margin-right: 8px;
                margin-top: 0px;
            }
        }
    }
    
    .search-title{
        font-weight: 700;
        font-size: 21px;   
    }
    .search-subtitle{
        color: #8a8a8a;
        margin-top: 4px;
        margin-bottom: 16px;
    }

    .search-footer{
        padding: 16px 0px
    }
}
