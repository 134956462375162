.tabContent{
  display: flex;
  flex: 1;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
  flex-wrap: wrap;
  overflow-y: scroll;
}

.tabContent .content{
  flex: 1;
  margin: 8px;
  flex-wrap: wrap;
}

.tabContent .content .bold{
  font-weight: 700;
}

.tabContent .content .gray{
  color: #8a8a8a;
}

.tabContent .row{
    display: flex;
    flex: 1;
    width: 100%;
    flex-wrap: wrap;
	  align-content: flex-start
}

.tabContent .row.right{
    justify-content: flex-end;
    margin-top: 16px
}

.tabContent .row.right button{
    margin: 4px
}

.datesContainer {
  display: table-row;
  justify-content: space-between;
}

.add-button{
  height: 21px;
  width: 21px;
}