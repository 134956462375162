.reach-visualization-container{
	width: 400px;

	.title{
		font-weight: 700;
	}

	.reach-value{
		font-size: 31px;
		color: #2196f3;
		text-align: center;
		font-weight: 700;
	}

	.reach-container{
		margin: 64px 0px  83px 0px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.select-all{
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.segmentation-action-container{
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}
}

.segmentationLabel{
	padding: 16px 0px;

	span{
		color: #2196f3;
		font-weight: 700
	}
}
.informations{
	display: flex;
	max-width: 480px;
	margin-right: 32px;
}