.header {
	display: flex;
    justify-content: space-between;
	/* box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.3); */
	border-bottom: 2px solid #f2f2f2;
	background-color: #fff;
	height: 50px;
    min-height: 50px;
	width: 100%;
	z-index: 4;
	position: sticky;
	top: 0;
}

.title-header {
	display: flex;
	font-weight: bold;
	font-size: 16px;
	align-items: center;
}

.option-header {
	display: flex;
	cursor: pointer;
}

.span-header{
	display: flex;
	padding: 5px;
	border-left: 2px solid #f2f2f2;
}

.icon-header {
	width: 30px;
	padding-left: 10px;
	padding-right: 10px;
}