.list{
    flex: 1;
    min-width: 150px;
}

.list .title{
    padding: 16px;
    padding-left: 32px
}

.list  li{
    display: flex;
    align-items: center;
    height: 21px;
    padding: 16px;
    position: relative;
}

.list li:nth-child(odd){
    background-color: #eaeaea;
}

.list li > h1{
   padding-left: 16px
}

.bold{
    font-weight: 700
 }

 .list .actionsl div > svg:hover > path{
    fill: #2196f3;
    transform: scale(1.1);
    transform-origin: center;
    transition: 0.5s
}

.list .actionsl div > svg > path{
    transform-origin: center;
    transition: 0.5s;
}

.list .actionsl div > svg{
    position: absolute;
    right: 16px;
    top: 14px
}

.list .actionsl div > svg:nth-child(2){
    position: absolute;
    right: 46px;
    top: 14px
}