.form-StoreData{
	display: flex;
	flex-wrap: wrap;
	padding-top: 20px;
	padding-left: 32px
}

.StorelData label.label-textarea {
	flex: 1;
	margin-left: 46px;
	margin-right: 46px
}

.btnSave{
	width: 180px;
	float: right;
	margin-right: 40px;
	margin-top: 18px
}

.form-StoreData input {
	width: 150px
}

.form-StoreDataDescription{
	display: flex;
	flex-wrap: wrap;
	padding-left: 32px
}

.form-StoreDataDescription input {
	width: 500px
}