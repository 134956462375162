.cardTabProfile {
	box-shadow: 3px -3px 25px rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    flex: 1;
    box-sizing: border-box;
    background-color: #FFF;
}

.cardTabProfile .header-cardTabProfile{
	display: flex;
	align-items: center;
	padding: 16px;
}
.option-cardTabProfile a{
	text-decoration: none;
	color: #636363;
	padding: 8px;
	cursor: pointer;
}

.option-cardTabProfile a.activeTab{
	font-weight: bold;
	color: black;
    border-bottom: 3px solid #2196f3;
}

.header-cardTabProfile .option-cardTabProfile.active{
	font-weight: bold;
	color: black;
	border-bottom: 3px solid #2196f3;
}

.route-profile{
    box-sizing: border-box;
	display: flex;
	flex: 1;
}