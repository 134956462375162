#timeline-container{
    padding: 16px 0px;

    .time-stamp-container{
        padding: 16px;
        user-select: none;
        position: relative;
        padding-left: 32px;
        padding-top: 0px;
    
        > .title{
            font-weight: 700;
            padding: 0px 0px 8px 8px;
        }

        .subtitle{
            color: #8a8a8a
        }

        .content{
            padding-top: 8px;
        }
    
        > .title::before{
            content: "";
            width: 16px;
            height: 16px;
            transition: 250ms;
            transition-delay: 500ms;
            background-color: #f2f2f2;
            position: absolute;
            left: 10px;
            top:0px;
            border-radius: 50%;
        }
    
        .time-fact-container{
            padding: 4px;
            display: flex;
            flex-direction: column;
    
            .container{
                border: solid 3px #f2f2f2;
                padding: 16px;
                border-radius: 10px;
                border-top-left-radius: 0px;
                border-bottom-right-radius: 30px ;
                margin-bottom: 8px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
    
                .title{
                    font-weight: 700;
                }
    
                .value{
                    font-size: 21px;
                }
            }
    
            .details{
                align-self: flex-end;
                cursor: pointer;
                padding-top: 8px;
                color: #2193f3;
            }
    
            .details:hover{
                text-decoration: underline;
            }
        }
    }
    
    .time-stamp-container::before{
        content: '';
        position: absolute;
        background-color: #f2f2f2;
        width: 3px;
        top: 0;
        bottom: 0;
        left: 16px;
        flex: 1;
        border-radius: 6px;
    }
    
    .time-stamp-container::after{
        content: '';
        position: absolute;
        background-color: #f2f2f2;
        width: 3px;
        top: 12px;
        bottom: 0;
        left: 16px;
        flex: 1;
        height: 0px;
        border-radius: 6px;
        transition: 500ms;
        background-color: #2193f3;
    }
    
    .time-stamp-container:hover::after{
        height: 100%;
        transition-delay: 100ms;
    }
    
    .time-stamp-container::before{
        content: '';
        position: absolute;
        background-color: #f2f2f2;
        width: 3px;
        top: 0;
        bottom: 0;
        left: 16px;
        flex: 1;
        border-radius: 6px;
        transition: 500ms;
    }
    
    .time-stamp-container:hover > .title::before{
        background-color: #2193f3;
        transition-delay: 0ms;
        transition: 500ms;
    }
}