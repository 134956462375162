.operatorData-page{
	flex: 1;
	display: flex
}

.operator-data{
	padding: 0px 16px;
	flex: 1;
	display: flex
}

.operatorData-form{
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 4px;
	padding-top: 20px;
	padding-left: 32px;
	flex: 1
}

.operatorData-content{
	display: flex;
	flex: 1;
	justify-content: space-between;
	flex-wrap: wrap;
	flex-direction: column;
}

.operatorData-row{
	display: flex;
	flex: 1
}

.operatorData-row input{
	width: 250px
}