.tooltip-container{
    min-width: 100px;
    background-color: #fff;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
    // border: solid 1px #eee;
    border-radius: 5px;
    padding: 16px;
    display: flex;
    flex-direction: column;

    h1{
        font-weight: 600;

        span{
            font-weight: 700;
        }
    }

    h2{
        color: #8a8a8a;
        font-size: 12px;
        padding-top: 4px;
    }

    ul{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-top: 8px;
    }

    li{
        display: flex;
        align-items: center;
        height: 20px;

        h1{
            font-weight: 100;
        }
    }

    .subtitle{
        height: 8px;
        width: 8px;
        border-radius: 4px;
        margin-right: 4px;
    }
}