.menu {
	transition: 0.5s;
	background-color: #FFF;
	overflow-x: hidden;
	/* box-shadow: 3px 10px 15px rgba(171, 174, 185, 0.4); */
	box-shadow: 3px 8px 20px rgba(0, 0, 0, 0.1);
	width: 55px;
    min-height: 100%;
    position: fixed;
    z-index: 99;
	user-select: none;
}

.menu.openMenu{
	width: 190px;
}

.menu:hover {
	width: 190px;
	transition: 0.5s;
    box-shadow: 10px 3px 20px rgba(0, 0, 0, 0.2);
}

.menu .option-menu {
	cursor: pointer;
	display: flex;
	font-size: 14px;
	font-weight: bold;
	color: #5a5a5a;
	align-items: center;
	padding: 5px;
	text-decoration: none;
}

.menu .option-menu:hover {
	border-left:#2196f3 4px solid;
}

.option-menu.menuActive svg, .menu .option-menu:hover svg, .option-menu.menuActive, .menu .option-menu:hover {
	stroke: #2196f3;
	color: #2196f3;
	transition: 100ms;
	background-color: #fafafa;
}


.id-menu {
	color: #fff;
    padding: 15px;
    margin-right: 10px;
    font-size: 16px;
}

.top {
	justify-content: space-between;
	background-color: #2196f3;
	height: 42px;
}

.top img {
	padding-right: 10px;
	height: 15px;
	width: 15px;
	transform: rotate(0deg);
	transition: 0.2s;
}

.icon-top.open{
	transform: rotate(45deg);
}

.option-menu.top:hover{
	background-color:  #2196f3;
}

.option-menu .icon-menu{
	padding-right: 5px;
}


.icon-menu img, .icon-menu svg {
	height: 25px;
	width: 25px;
	padding: 10px;
}

.icon-menu.coupon img {
	transform: rotate(-35deg)
}