#smartphone-frame{
    height: 80vh;
    background-color: #fff;
    border: 8px solid #212121;
    box-sizing: border-box;
    min-width: 340px;
    border-radius: 42px;
    min-height: 700px;
    max-height: 700px;
    user-select: none;
    max-width: 350px;
    padding: 18px 0px;
    padding-bottom: 8px;
    position: sticky;
    top: 137px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    transition: 1000ms;
    box-shadow: 3px 3px 50px rgba(0,0,0,0.2);

    .content{
        width: 100%;
        overflow: scroll;
    }
}

#smartphone-frame::before{
    height: 32px;
    border-radius: 16px;
    width: 60%;
    background-color: #212121;
    content: "";
    position: absolute;
    top: -16px;
    z-index: 999;
}

.notification-manager{
    position: absolute;
    background-color: #282828;
    color: #fff;
    box-shadow: 0px 3px 15px rgba(0,0,0,0.3); 
    padding: 16px 8px;
    border-radius: 10px;
    width: 90%;
    display: flex;
    transition: 300ms;
    max-height: 100px;
    top: -100%;
    opacity: 0;
    min-height: 30px;
    word-break: break-all;

    .icon{
        height: 32px;
        min-width: 32px;
        background-color: #000;
        border-radius: 5px;
        margin-right: 8px;
    }

    h1{
        font-weight: 700;
        min-height: 20px;
    }
}

.notification-manager.show{
    animation: notify 500ms;
    opacity: 1;
    animation-fill-mode: forwards;
}

.notification-manager.hide{
    animation: hideNotify 500ms;
    animation-fill-mode: forwards;
}

@keyframes notify{
    0%{
        top: -100%;
    }
    50%{
        top: 40px;
    }
    75%{
        top: 18px;
    }
    100%{
        top: 22px;
    }
}

@keyframes hideNotify{
    0%{
        right: 0px;
        top: 22px
    }
    100%{
        right: -100%;
        top: 22px
    }
}