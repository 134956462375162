.modalMessage {
	position: absolute;
	background-color: rgba(0,0,0,0.3);
	width: 100%;
	height: 100%;
	display: none;
	justify-content: center;
	align-items: center;
	user-select: none;
}

.body-modalMessage{
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 3px;
	background-color: #fff;
	box-shadow: 1px 1px 10px rgba(0,0,0,0.2);
	width: 25%;
	height: 25%;
}