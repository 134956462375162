.loja-content {
	display: flex;
	flex-direction: column;
	height: 88%;
}

.loja-main{
	display: flex;
	flex-direction: column;
	flex: 1;
}

.loja-content input{
	align-self: center;
	width: 97%;
}

.title-loja {
	font-size: 21px;
	font-weight: bold;
	margin-top: 20px;
	margin-left: 20px;
}

.list-lojaProduct{
	display: flex;
	flex-wrap: wrap;
	padding-left: 20px;
	flex: 1;
	padding-right: 20px;
}

.option-loja{
	display: flex;
	justify-items: center;
	justify-content: space-between;
	}

.btn-newItem{
	height: 40px;
	width: 100px;
	margin-left: 20px;
	background-color: #fff;
	color: #1976d2;
	border: 1px solid #1976d2;
}

.option-itens{
	float: right;
	margin-right: 32px
}

.options-bar{
	display: flex;
    justify-content: space-between;
    border-bottom: solid 1px #f1f1f1;
	height: 42px;
	padding: 16px 23px
}