.loadingMessage{
    flex: 1;
    position: absolute;
    display: flex !important;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: #8a8a8a;
    user-select: none;
    pointer-events: none;
}

.loadingMessage h1{
    font-weight: 700;
    font-size: 16px;
    margin: 4px;
    text-align: center;
}

.loadingMessage h2{
    font-size: 14px;
}

.loadingMessage img{
    width: 60px;
    height: 60px;
    margin: 16px
}

.loading-img{
    align-self: center
}

.progress-bar-loading{
    background: linear-gradient(270deg ,#8200ff, #ff00ea, #ffcd00, #94ff00, #00d0ff,#8200ff);
    background-size: 1000% 1000%;
    animation: backgroundAnimation 10s linear infinite forwards;
    height: 8px;
    color: #fff;
    display: flex;
    align-items: center;
    border-radius: 10px;
    justify-content: center;
    font-size: 10px;
}

@keyframes backgroundAnimation {
    0%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

.page-blocker{
    position: fixed;
    z-index: 99999999999999;
    background-color: rgba(255, 255, 255, 0.9);
    display: grid;
    place-items: center;
    top: 0px;
    bottom: 0;
    user-select: none;
    overflow: hidden;
    left: 0;
    right: 0;
    cursor: wait;
}