.DateRangeArrow{
    position: absolute;
    top: 8px;
    padding: 8px;
    border-radius: 50%;
}

.DateRangeArrow:hover{
    background-color: #f2f2f2;
}

.DateRangeArrow:active{
    transform: scale(0.9);
}

.CalendarDay__default{
    vertical-align: middle;
}

.CalendarDay__selected_span {
    background: #d5edff;
    border: 1px double #2196f3;
    color:#2196f3;

    
}

.CalendarDay__selected_span:hover, .CalendarDay__selected, .CalendarDay__selected:hover{
    background-color: #2196f3;
    color: #fff;
}

.DateInput_input__focused {
    border-color: #2196f3;
}

.datesContainer{
    padding-left: 4px;
}

.datePickerLabel{
    padding-bottom: 4px;
}

.DateRangePickerInput__withBorder{
    border: 1px solid #ccc;
    height: 38px;
    border-radius: 3px;
}