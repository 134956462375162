.chartContainer {
	display: flex;
	margin: 8px;
	border-radius: 3px;	
}

.chartContainer.shadow{
	box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.3);
	background-color: #FFF;
}

.chartContainer.border{
	border: solid 2px #eee
}

.chartContent{
	padding: 16px 32px 16px 0px;

}

.areaChart{
	flex-direction: column;
	display: flex;
	justify-content: center;
	align-items: center;
}

.chartContent h1{
	font-weight: 700;
	padding-bottom: 16px;
	padding-left: 16px;
	border-bottom: solid 1px #f2f2f2;
	margin-bottom: 32px;
	margin-right: -32px
}
