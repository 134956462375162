.main-router{
	display: flex;
    min-height: 100vh;
    max-height: 100vh;
}

.main {
	display: flex;
	min-height: 100%;
}

.title-page{
    font-weight: 700;
    font-size: 21px
}

.page-title-header{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 16px ;
    max-height: 52px;
}

.container {
	display: flex;
	flex-direction: column;
    flex: 1;
    max-width: calc(100vw/1.5);
}

.container-router{
    flex: 1;
    padding-left: 56px;
    display: flex;
    position: relative;
    flex-direction: column;
}

.hidden {
    display: none;
}

.input-shadow {
	min-height: 22px;
	font-size: 1.1em;
	box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.5);
	margin: 0px;
	border: none
}



.container-search{
	display: grid;
	flex-wrap: wrap;
	flex: 1;
	align-content: flex-start;
	overflow-y: scroll;
	user-select: none;
}


@media (min-width: 733px)  {
	.container-search{
		grid-template-columns: 1fr 1fr
	}
}

@media (min-width: 1054px)  {
	.container-search{
		grid-template-columns: 1fr 1fr 1fr
	}
}

@media (min-width: 1370px)  {
	.container-search{
		grid-template-columns: 1fr 1fr 1fr 1fr
	}
}
