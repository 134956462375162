.asideUser {
	display: flex;
	flex-direction: column;
	padding-left: 15px;
	padding-right: 15px;
	min-width: 200px;
	width: 20%;
	height: 100%;
	max-width: 250px;
	overflow: auto;
}


.asideCard1, .asideCard2{
	margin: 3px;
	max-height: 200px;
}


@media (max-width: 650px) {
	.asideUser {
		width: 94%;
		height: 210px;
		flex-direction: row;
		overflow-y: hidden;
	}
}

