.root  {
	display: flex;
	flex-direction: column;
    min-height: 100%;
    flex: 1;
	background-color: #f5f6fa;

}

a { 
	color: inherit; 
	text-decoration: none;
} 

body{
	background-color: #f5f6fa;
}


.popup-content {
    border-radius: 5px;
    width: 70%;

    border: none!important
}

.popup-overlay {
    justify-content: center;
}

.client {
	width: 100%;
	height: 95%;
}

.smooth-scroll{
    scroll-behavior: smooth
}

::-webkit-scrollbar { 
    display: none; 
}

.input-error::placeholder{
    color: #ff5f58
}

.input-error{
    color: #ff5f58;
    border: solid 1px #ff5f58;
    animation: shake 0.3s

}

.shake{
    animation: shake 0.3s
}

.react-confirm-alert-body > h1 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 16px;
}

.react-confirm-alert-button-group{
    justify-content: flex-end;
}

.react-confirm-alert-button-group button:first-child{
    color: #2196f3;
    background-color: #fff;
}

.react-confirm-alert-button-group button:last-child{
    background-color: #2196f3;
    color: #fff;
}

.DateInput_input__small {
    width: 83px;
    // border-radius: 8px!important
}

.TabContainer, .tabScreen-container{
    label{
        margin-bottom: 8px;
        margin-right: 8px;
    }
}

.SingleDatePickerInput__withBorder{
	border-color: #ccc!important;
	border-radius: 3px!important
}

// .DateInput__small{
//     border-radius: 3px!important
// }

.SingleDatePicker{
    width: 100px
}

.container{
    max-width: 100vw;
}

@media (max-width: 1300px)  {
	.container, .client, .main {
		height: auto;
	}
}

@media (max-width: 650px)  {
	.root{
		height: auto;
	}
}

@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

@keyframes bounce{
    0%{transform:scale(1)}
    25%{transform:scale(0.8)}
    75%{transform:scale(1.19)}
    100%{transform:scale(1)}
}

@keyframes click{
    0%{transform:scale(1)}
    50%{transform:scale(0.4)}
    100%{transform:scale(0.9)}
}

@keyframes opacityAnimation{
    0%{opacity:(0)}
    100%{opacity:(1)}
}

@keyframes shake{
    0%{transform:translateX(8px)}
    25%{transform:translateX(-8px)}
    50%{transform:translateX(8px)}
    75%{transform:translateX(-8px)}
    100%{transform:translateX(0px)}
}

.placeholder-shiny{ 
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #ddd 8%, #f9f9f9 20%, #ddd 33%);
    background-size: 800px 104px;
    position: relative;
}

.status{
    height: 12px;
    width: 12px;
    border-radius: 100%;
}

.status.yellow{
	background-color: #ffba01;
    box-shadow: 0px 0px 5px #ffba01
}

.status.red{
	background-color: #ff5f58;
    box-shadow: 0px 0px 5px #ff5f58
}

.status.green{
    background-color: #2ac940;
    box-shadow: 0px 0px 5px #2ac940
}

.icon-bounce{
    padding: 8px;
    height: 21px;
    transition: 0.5s;
    border-radius: 100%;
}

// .icon-bounce:hover > path, .icon-bounce:hover{
//     fill: #2196f3;
//     transform-origin: center;
//     animation: bounce 500ms
// }

.icon-bounce:active{
    transform: scale(0.7);
    transition: 0.3s;
}

.icon-bounce:hover{
    background-color: #f0f0f0;
}

.react-confirm-alert-overlay{
    z-index: 9999;
}