.span-date {
	display: flex;
	position: relative;
}

.textInput{
    flex: 1
}

.title{
    flex: 1;
    width: 100%;
    align-self: center;
}

.title .label{
    padding: 4px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3{
        font-weight: 100;
        color: #8a8a8a;
        font-size: 12px;
    }
    
}

.emoji {
    button{
        width: 42px
    }
}