.store{
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 100%;
	padding: 0px 16px;
}

.header-store{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 16px 0 0 0;
	min-height: 62px;
	z-index: 1;
	margin-bottom: 10px;
	box-shadow: 0px 16px 20px #f5f6fa
}

.container-store{
	display: grid;
	flex-wrap: wrap;
	flex: 1;
	align-content: flex-start;
	overflow-y: scroll;
	user-select: none;
}

@media (min-width: 733px)  {
	.container-store{
		grid-template-columns: 1fr 1fr
	}
}

@media (min-width: 1054px)  {
	.container-store{
		grid-template-columns: 1fr 1fr 1fr
	}
}

@media (min-width: 1370px)  {
	.container-store{
		grid-template-columns: 1fr 1fr 1fr 1fr
	}
}

.footer-store{
	min-height: 62px;
	/* display: flex; */
}

.paginator{
	min-width: 160px;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	position: relative;
	user-select: none
}

 .number-pagination {
	 padding: 0;
	 padding-bottom: 4px;
	 min-width: 160px;
 }

 .arrow-right {
	 position: absolute;;
	 right: 0;
	 height: 42px;
 }

 .arrow-left {
	position: absolute;;
	left: 0;
	height: 42px;
}