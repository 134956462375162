#search-search-container{
    flex: 1;
    margin: -4px;
    display: flex;
    margin-right: 0;
    position: relative;
    flex-direction: column;
    padding: 16px;
    background-color: #fbfcfd;
    scroll-behavior: smooth;
}

#search-info-container{
    min-width: 284px;
    max-width: 284px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    user-select: none;

    .row{
        display: flex;
        justify-content: space-between
    }
}

#search-list-container{
    flex: 1;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: start;
    // grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    // grid-template-rows: 200px;
    margin-top: 16px;
    border-radius: 5px;
    // background-color: #fff;
}

#search-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -16px;
    position: relative;

    img{
        height: 42px;
        width: 42px;
    }
}