.listItemContainer{
    display: flex;
    align-items: center;

    .border{
        border: 1px #ccc solid;
        padding: 12px 8px;
        border-radius: 3px;
    }

    svg{
        width: 21px;
        margin-left: 4px;
        color: #8a8a8a;
        opacity: 0;
        padding: 8px;
    }

    svg:active{
        transform: scale(0.9);
    }
}

.listItemContainer:hover{
    svg{
        opacity: 1;
    }
}

.listItemContainer.loading{
    opacity: 0.5;
}