.normal-button.invert:active, .normal-button{
    height: 32px;
    background-color: #2196f3;
    color: #FFF;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    user-select: none;
    border: solid 1px #FFF;
    /* text-transform: uppercase; */
    transition: 0.5s;
}

.has-icon{
    svg{
        margin-left: 8px;
    }
}

.normal-button:active, .normal-button.invert{
    background-color: transparent;
    color: #1976D2;
    border: #1976D2 1px solid;
    border-radius: 5px;
}

.normal-button.invert:active{
    border: solid 1px #FFF
}

.text-only{
    border: 1px solid transparent;
    background: transparent;
    color:#2196f3;
    box-shadow: none!important;
    transition: .5s;
    padding-left: 0;
    padding-right: 0;
}

.text-only:active{
    border: 1px solid transparent;
}

.normal-button.disabled {
    background-color: #ccc;
    box-shadow: none!important;
    transition: 0.5s;
}

.normal-button.invert.disabled {
    color: #ccc;
    background-color: transparent;
    border-color: #ccc;
    box-shadow: none!important;
    transition: 0.5s;
}

.text-only.disabled {
    color: #ccc;
    background-color: transparent;
    box-shadow: none!important;
    transition: 0.5s;
}

.text-only:active{
    font-size: 13px;
    transition: .5s;
}

.normal-button span{
    height: 32px;
    width: 32px;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    margin-right: -4px 
}

.normal-button.small{
    max-width: 100px;
    align-self: flex-end;
    float: right;
    margin-top: 32px;
    margin-right: -12px
}

.normal-button.small-button{
    max-width: 100px;
    min-width: 64px;
}

.shine-button {
    box-shadow: 0px 3px 10px rgb(33, 150, 243);
    border: none;
    transition: .5s;
}

.shine-button:active{
    box-shadow: none;
}