.tabScreen-content{
    flex: 1;
    padding: 16px 24px;
    display: flex;

    .content{
        flex: 1;
    }

    .informations{
        flex: 1;
        flex-wrap: wrap;
    }
}

.tabScreen-container{
    box-shadow: 3px -3px 25px rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #FFF;
}

.tabScreen-header{
    display: flex;
    padding: 16px;

    a.activeTab{
        font-weight: bold;
        color: black;
        border-bottom: 3px solid #2196f3;
    }
}

.tabScreen-routes{
    flex: 1;
    display: flex
}