.actions{
    svg:hover{
        transform: scale(1.1);
        transform-origin: center;
        transition: 0.5s
    }

    svg:active{
        transform: scale(0.8);
        transform-origin: center;
        transition: 0.5s
    }
}

#emoji-picker{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 9;
    
    svg{
        margin-bottom: 8px;
    }

    .emoji-container{
        max-height: 0px;
        overflow: hidden;
        transition: 300ms;
    }

    .emoji-container.open{
        max-height: 320px;
        transition: 300ms;
    }
}