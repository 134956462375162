.abstractModal-container{
    flex: 1;

    .value{
        font-weight: 700;
    }

    header{
        display: flex;
        align-items: center;
        margin-bottom: 32px;

        .info{
            margin-left: 16px;

            h2{
                color: #2196f3;
                padding: 4px 0px;
            }
            
            h3{
                color: #8a8a8a;
            }
        }
    }

    main{
        h2{
            margin-bottom: 16px;
        }

        ul{
            display: flex;
            overflow: scroll;
        }

        .selectableItem{
            margin: 0 
        }
    }
}