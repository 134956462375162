/* CSS RESET */

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

html, body, #root {
	height: 100%;
	font-size: 14px;
}

body {
  font-family: Arial;
  overflow-x: hidden;
}

button, input, textarea, select{
	border: none;
	padding: 7px;
	outline: none;
	border-radius: 3px;
}

button {
	width: 100px;
	color: #FFF;
	cursor: pointer;
	border-radius: 20px;
	background-color: #2196f3;
	/* text-transform: uppercase; */
	height: 42px;
	margin: 4px
}

button:active{
	border: solid 1px #2196f3;
	color: #2196f3;
	background-color: #FFF
}

 input, textarea, select {
	border: 1px solid #ccc;
	padding: 7px;
	margin-top: 5px;
	resize: none;
	background-color: #FFF
 }

label {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	/* margin-bottom: 21px; */
}

label input{
	min-height: 24px;
}


select{
	height: 40px;
}


::-webkit-scrollbar-track {
	background-color: #fff;
}
::-webkit-scrollbar {
	width: 7px; background: #fff;
}
::-webkit-scrollbar-thumb {
	background: #e2e2e2;
}