.promotion{
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 100%;
	padding: 0px 16px;
}

.header-promotion{
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	padding: 16px 8px 0 8px;
	min-height: 62px;
	margin-bottom: 10px;
}

.none{
	display: none
}


.footer-promotion{
	min-height: 62px;
	display: flex;
	justify-content: space-between;
	padding: 32px 0;
}

.paginator{
	min-width: 160px;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	position: relative;
	user-select: none
}

 .number-pagination {
	 padding: 0;
	 padding-bottom: 4px;
	 height: 42px;
	 width: 160px;
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 text-align: center;
	 color: #2196f3;
 }

 .number-pagination:hover {
	color: #67bbff;
}

.number-pagination:active {
	font-size: 12px;
	
}

.arrow-right {
	position: absolute;;
	right: 0;
	height: 42px;
}

.arrow-left {
	position: absolute;;
	left: 0;
	height: 42px;
}

.option-footer{
	position: relative;
}