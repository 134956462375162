.contacts{
	display: flex;
	flex: 1;
	flex-direction: column;
	height: 100%;
}

.form-contactsData {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 16px 32px;
	border-bottom: solid 1px #f1f1f1;
	align-items: center
}

.contactsContents{
	display: flex;
	flex-wrap: wrap;
	position: relative;
	flex: 1;
	align-content: flex-start
}

.contactsLabel{
	width: 42% !important
}

.contactsData .contactsLabel.label-textarea {
	flex: 1;
	margin-left: 46px;
	margin-right: 46px
}

.btnSave{
	width: 180px;
	float: right;
	margin-right: 40px;
	margin-top: 18px
}