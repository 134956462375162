.ranking-list li{
	display: flex;
	flex: 1;
	padding: 8px;
	position: relative;
	justify-content: center;
	align-items: center;
}

.ranking-list {
	h2{
		padding-top: 4px;
	}
}

.ranking-list li:nth-child(odd){
    border-bottom:solid 1px #f2f2f2;
	border-top:solid 1px #f2f2f2;
}
