.card{
    display: flex;
    flex-direction: column;
    transition: 500ms;
    max-width: 700;
    transition: 500ms;

    .subtitle-gray{
        color: #8a8a8a;
    }

    header{
        padding: 16px;
        border-bottom: solid 1px #f2f2f2;
        display: flex;

        svg:active{
            transform: scale(0.9);
        }
    }

    main{
        position: relative;
        flex: 1;
        display: flex;
        // flex-wrap: wrap;
        flex-direction: column;
    }

    footer{
        display: flex;
        flex-direction: row-reverse;

        h2{
            color: #8a8a8a;
        }
    }

    .normal-button.text-only{
        margin-bottom: -8px;
        margin-right: -16px;
    }
}
