.SwitchList{
    background-color: #fff;
    min-height: 30px;
    padding: 4px;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.3);
    position: relative;
    user-select: none;
}

.SwitchList.flat{
    box-shadow: none;
    border: 1px solid #ccc;
}

.SwitchList .SwitchBackground{
    border-radius: 3px;
    position: absolute;
    height: calc(100% - 8px);
    z-index: 1;
    transition: .4s ease-in-out ;
}
.SwitchList div{
    display: flex;
    flex: 1;
    position: relative;
    z-index: 2;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.SwitchList div p{
    cursor: pointer;
    transition: .4s ease-in-out ;
}