.form-storeData {
	display: flex;
	padding-top: 20px;
	flex: 1;
	flex-direction: column;
	padding-left: 32px
}

.form-storeData > label{
	margin: 0
}

.storeData-row{
	display: flex;
	flex: 1
}

.btnSave{
	width: 180px;
	float: right;
	margin-right: 40px;
	margin-top: 18px
}

.CEP{
    position: relative
}
.CEP input{
    margin-right: 32px;
	width: 110px
}
.CEP img{
    position: absolute;
    right: 10px;
    top: 26px
}

.form-number{
	width: 115px
}

.form-name{
	width: 355px
}

.form-district{
	width: 295px
}