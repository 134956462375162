.personalDataAddress{
	padding: 0px 16px
}

.form-personalDataAddress {
	display: flex;
	flex-wrap: wrap;
	border-bottom: solid 1px #f1f1f1;
	padding-bottom: 4px;
	padding-top: 20px;
	padding-left: 32px
}

.personalDataAddress-content{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	flex-direction: column
}

.CEP{
    position: relative
}
.CEP input{
    margin-right: 32px;
	width: 110px
}
.CEP img{
    position: absolute;
    right: 10px;
    top: 26px
}

.form-number{
	width: 115px
}

.form-name{
	width: 355px
}

.form-district{
	width: 295px
}

.btnSave-address{
	width: 180px;
	float: right;
	margin-right: 40px;
	margin-top: 18px
}

.refresh-icon {
	width: 20px;
	display: inline-flex;
	cursor: pointer;
}

.address-content{
	display: flex;
	flex-wrap: wrap;
	padding-top: 4px
}

#address-list li:last-child{
	animation: bounce 0.5s
}

.personalDataAddress-row{
	display: flex;
	flex: 1
}

@keyframes bounce{
	0% { transform: scale(0) }
	100% { transform: scale(1) }
}