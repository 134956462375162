.score {
	width: 90px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.score img {
	width: 14px;
	padding: 2px;
}