.userDropBox{
    width: 280px;
    background-color: #fff;
    position: absolute;
    right: 2px;
    top: 51px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
    border-radius: 3px;
    max-height: 0;
    overflow: hidden;
}

.info-userDropBox{
    display: flex;
    padding: 16px;
    user-select: none;
}

.info-userDropBox img{
    height: 42px;
    width: 42px;
    background-color: #d5edff;
    border-radius: 100%;
    padding: 8px;
}

.info-userDropBox .data{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 16px
}

.info-userDropBox .data h1{
    font-weight: 700
}

.info-userDropBox .data h2{
    color: #828282
}

.userDropBox .footer{
    background-color: dodgerblue;
    height: 42px;
    padding:8px 16px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between
}

.button-personaldata{
	color:dodgerblue;
	background-color: white;
	text-decoration: none;
	font-weight: 700;
	font-size: 14px;
	border-radius: 32px;
	padding:12px 16px;
	border: 1px solid #fff;
}


.info-user {
	display: flex;
    align-items: center;
    border-left: 2px solid #f2f2f2;
    padding: 0px 15px 0px 4px;
	z-index: 9999;
}

.info-user.open .userDropBox{
	max-height: 1000px;
}


.info-user:hover .userDropBox{
	max-height: 1000px;
}

.name-user{
	font-weight: bold;
}

.photo-user {
	border-radius: 100%;
	overflow: hidden;
	width: 35px;
	height: 35px;
	margin-left: 10px;
}

.photo-user img {
	width: 100%;
	height: 100%;
}