.DatePicker{
    min-height: 200px;
    display: flex;
    align-content: center;
    align-items: center;
    user-select: none;
    min-width: 310px;
}

.DatePicker-Calendar{
    flex: 1;
    min-height: 200px;
    max-width: 360px;
    align-content: center;
    justify-content: center;
    display: flex;
    flex-direction: column
}

.DatePicker-Calendar .weekLabel:hover, .DatePicker-Calendar .weekLabel.active{
    background-color: #2196f3;
    color: #FFF
}

.weekLabel-exception{
	background-color: #0F9F4F;
    border-top-left-radius: 21px;
    border-top-right-radius: 21px;
    color: #f2f2f2;
	margin: 0 -4px;
}

.DatePicker-Calendar .selector{
    background-color: #2196f3;
    display: flex;
    justify-content: space-between;
    min-height: 28px;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: #fff
}

.DatePicker-Calendar .selector .button{
    background-color: #1976D2;
    width: 32px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 32px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    text-align: center
}

.DatePicker-Calendar .selector .monthLabel{
    flex: 1;
    text-align: center
}


.DatePicker-Calendar ul{
    display: grid;
    grid-template-columns: repeat(7,1fr);
    grid-column-gap: 8px;
    padding: 8px;
    border: 1px solid #ccc;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.DatePicker-Calendar ul li{
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    min-height: 30px
}

.DatePicker-Calendar ul li.day:hover, .DatePicker-Calendar ul li.day.active, .DatePicker-Calendar .weekLabel-exception:hover{
    background-color: #037B39;
    color: #FFF
}

.DatePicker-Calendar ul li.day.start-day{
    background-color: #7BBB4C;
    color: #2a4303;
	border-top-left-radius: 21px;
	border-bottom-left-radius: 21px;
	margin: 0 -4px;
}

.DatePicker-Calendar ul li.day.end-day{
    background-color: #E14B46;
	border-top-right-radius: 21px;
	border-bottom-right-radius: 21px;
    color: #450405;
	margin: 0 -4px;
	position: relative;
}

.DatePicker-Calendar ul li.day.between-days{
    background-color: #d5edff;
	margin: 0 -4px;
	color: #2196f3;
	position: relative;
}

.DatePicker-Calendar ul li.day.between-days.exception{
    background-color: #f2f2f2;
	color: #8a8a8a
}

.DatePicker-Calendar ul li.day.between-days.exception:hover{
    background-color: #b4b4b4;
	color: #8a8a8a
}

.DatePicker-Calendar ul li.day.between-days.exception::after{
	content: '';
	width: 20px;
	height: 1px;
	background: #8a8a8a;
	position: absolute;
	transform: rotate(-45deg)
}

.DatePicker-Calendar ul li.day.between-days:hover{
	background-color: #2196f3;
	color: #FFF;
    border-radius: 0
}

.DatePicker-Calendar ul li.day.same-day{
    background-image: linear-gradient(to bottom right, #7BBB4C 50%, #D32F2F 50%);
	margin: 0 -4px;
	border-radius: 21px;
	color: #FFF
}

.DatePicker-Calendar ul li.exception-day{
    background-color: #05A24B;
    color: #FFF;
	margin: 0 -4px;
}

.DatePicker-Calendar ul li.day.exception-day:hover{
    border-radius: 0;
	background-color: #037B39;
}

.top-left{
    border-top-left-radius: 3px
}

.DatePicker-Calendar ul li.day.between-days:nth-child(14){
    border-top-right-radius: 3px
}

.DatePicker-Calendar ul li.day.between-days:last-child{
    border-bottom-right-radius: 3px
}
