.image-picker-container{
    max-height: 100px;
    flex: 1;
    display: flex; 
    align-items: center;
    justify-content: center;
    background-color: #d5edff;
    color: #2196f3;
    border: 1px dashed #2196f3;
    text-align: center;
    padding: 16px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
}

.image-picker-container:hover > #image-picker-remove{
    opacity: 1;
}

.image-picker-container:hover > #image-picker-label{
    filter: blur(25px);
}

#image-picker-label{
    height: 100%;
    width: 100%;
    position: absolute;
    transition: 0.5s;
}

#image-picker-remove{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: #FFF;
    background-color: rgba(0, 0, 0, 0.1);
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.5s;
    z-index: 999;
}

.selected{
    background-color: #fff;
    border-color: #fff;
}