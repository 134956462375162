.title-historic {
	font-weight: bold;
	font-size: 21px;
	padding: 5px;
	padding-left: 20px;
	padding-bottom: 15px;
}

.historic-page{
	width: 500px;
}