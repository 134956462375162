.TinyCard {
	cursor: pointer;
	background-color: #FFF;
	box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.3);
	display: flex;
	align-items: center;
	border-radius: 3px;
	flex: 1;
	height: 35px;
	margin: 8px;
	padding: 8px;
	position: relative;
	transition: 0.3s;
}

.TinyCard:hover{
	box-shadow: 0px 0px 15px #2196f360
}

.TinyCard:active{
	transition: 0.3s;
	box-shadow: none
}

.content-TinyCard {
	display: flex;
	height: 100%;
	width: 100%;
	padding-left: 10px;
	flex-direction: column;
	justify-content: space-between;
}

.title-TinyCard {
	font-weight: bold;
	padding-top: 10px;
	text-transform: capitalize
}
