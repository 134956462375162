.border-list{
	li{
		border: 1px solid #ccc;
		padding: 8px;
		border-radius: 3px;
		margin: 4px 0px;

		.title{
			font-weight: 700;
		}

		h2{
			color: #8a8a8a;
		}
	}
}