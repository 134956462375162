.addComboListView{
    width: 500px;
    position: relative;
    
    .title{
        font-weight: 700;
        padding-bottom: 8px
    }

    .subtitle{
        padding: 16px 0px;
        font-weight: 700
    }

    .border{
        border: 1px solid #ccc;
        border-radius: 3px;
        // margin-top: 8px;
        padding: 8px;
    }
    
    .comboContainer{
        display: flex;
        margin: -9px -9px 8px -9px;

        select{
            flex: 1;
            margin: 0;
            border-radius: 0px;

            option{
                padding: 5px;
                min-height: 200px;
            }
        }
    }

    .add-button{
        background-color: #2196f3;
        margin: 0;
        color: #FFF;
        min-height: 40px;
        width: 40px;
        border: none;
        border-radius: 0px;
        padding: 16px;
    }

    .selecteds-list-container{

    }
}

.input-error{
    color: #ff5f58;
    border: solid 1px #ff5f58;
    animation: shake 0.3s

}

.input-error::placeholder{
    color: #ff5f58;
}

.shake{
    animation: shake 0.3s;
}

@keyframes shake{
    0%{transform:translateX(8px)}
    25%{transform:translateX(-8px)}
    50%{transform:translateX(8px)}
    75%{transform:translateX(-8px)}
    100%{transform:translateX(0px)}
}