.selectable-list{
	flex: 1;
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	justify-content: flex-start;

	.loadingGif{
		margin-right: 8px
	}
	
}

.selectable-list h1{
	padding: 4px 0px;
	padding-bottom: 8px;
	font-weight: 700;
}

.selectable-list li{
	padding: 4px;
	background-color: #f2f2f2;
	margin: 4px 0px
}

.selectableList-container{
	margin: 4px;
	flex: 1;
	
	.list-item{
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: 5px;
		margin: 8px 0;
		min-width: 230px
	}
	
}

.addPermission-button{
    max-height: 30px;
    max-width: 30px;
    padding: 4px;
    margin: 0 16px;
    position: relative;
    z-index: 1;
}

.addPermission-button.asideList-button{
    right: 8px;
    margin: 0!important;
    height: 16px!important;
    width: 16px!important;
    padding: 8px
}

.addPermission-button:hover, .addPermission-button:active {
    animation: bounce 0.5s
}

.addPermission-button:active{
    transform: scale(0.8);
    animation: bounce 0.5s
}

.selectable-title{
	flex: 1;
	padding: 8px;
	box-sizing: border-box;
	margin-right: 8px;
	
	h1{
		font-weight: 700
	}
}

.selectable-title h1{
	padding-bottom: 4px
}

.selectable-title h2{
	padding: 0px 4px
}

.selectable-list-container{
	overflow: scroll;
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 8px 16px;
	user-select: none;
}

.emptyListItem{
    color: #2196f3;
    border: dashed 1px #2196f3;
	user-select: none;
	background-color: #d5edff!important;
	border-radius: 5px;

    svg, path, circle{
        fill: #2196f3;
        margin: 16px;
    }
}