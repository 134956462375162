.popup-trigger{
    color: #2196F4;
    transition: .5s;
}

.popup-trigger:hover{
    transform: scale(0.9);
    
}

.popup-trigger:active{
    transform: scale(0.8);
}

.popup-container .title{
    font-weight: 700;
    flex: none;
}