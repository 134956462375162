.list-modal-style{
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: rgb(251, 252, 253);

    .title{
        font-weight: 700;
        font-size: 16px;
        flex: none;
    }
    .subtitle{
        color:#8a8a8a;
        flex: 0;
        padding-bottom: 16px;
    }

    label{
        flex: 1
    }

    ul{
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: scroll;
        width: 100%;
        padding: 8px 0px;
        max-height: 500px;

        .list-modal-list-item{
            padding: 8px;
            border: 1px solid #ccc;
            border-radius: 3px;
            margin-bottom: 4px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 30px;

            h1{
                font-weight: 700;
                padding: 2px 0px;
            }
        }

        .list-modal-list-item.empty{
            color: #2196F4;
            background-color: #d5edff;
            border: 1px dashed #2196f3;
        }

        .list-modal-list-item.loading{
            opacity: 0.5;
        }
    }

    footer{
        padding-top: 16px;

        .title{
            font-weight: 700;
            font-size: 14px;
        }

        div{
            display: flex;
            align-items: flex-end;
            background-color: #fff;
            margin: -16px;
            padding: 16px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
    
            select, label{
                margin: 0px;
            }
        }
    }

    .action-icon{
        height: 21px;
        width: 21px;
        padding: 4px;
        transition: 0.5s;
    }

    .action-icon:hover{
        animation: bounce 500ms
    }
    
    .action-icon:active{
        transform: scale(0.7);
        transition: 0.5s
    }

    .add-button{
        align-self: center;
        margin-bottom: -5px;
    }
}

.trigger-toggle{
    padding: 0px 8px;
    color: #2296F3;
    transition: 500ms
}

.trigger-toggle:active{
    padding: 0px 8px;
    color: #2296F3;
    transition: 500ms;
    transform: scale(0.8);
}

.trigger-toggle:hover{
    color: #d5edff;
}