.container-date {
    display: flex;
    margin-bottom: 1px;

    .column{
        display: table-column;
        padding: 4px;

        .row{
            display: flex
        }
    }
    .dates-label {
        padding-bottom: 0.5em
    }
}