.TabContainer {
	box-shadow: 3px -3px 25px rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    flex: 1;
    box-sizing: border-box;
    max-height: 100%;
    background-color: #FFF
}

.TabContainer .header-cardTabc{
	display: flex;
	align-items: center;
	padding: 16px;
}

.option-cardTabc a{
	text-decoration: none;
	color: #636363;
	padding: 8px;
	cursor: pointer;
}

.option-cardTabc a.active-user{
	font-weight: bold;
	color: black;
    border-bottom: 3px solid #2196f3;
}

.routec{
    box-sizing: border-box;

    flex: 1;

}

/* 
@media (max-width: 790px) {
	.cardTab {
    	width: 99%;
    	padding-bottom: 20px;
	}
} */


