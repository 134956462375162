/* CSS RESET */

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

html, body, #root {
	height: 100%;
	font-size: 14px;
}

body {
  font-family: Arial;
  overflow-x: hidden;
}

button, input, textarea, select{
	border: none;
	padding: 7px;
	outline: none;
	border-radius: 3px;
}

button {
	width: 100px;
	color: #FFF;
	cursor: pointer;
	border-radius: 20px;
	background-color: #2196f3;
	/* text-transform: uppercase; */
	height: 42px;
	margin: 4px
}

button:active{
	border: solid 1px #2196f3;
	color: #2196f3;
	background-color: #FFF
}

 input, textarea, select {
	border: 1px solid #ccc;
	padding: 7px;
	margin-top: 5px;
	resize: none;
	background-color: #FFF
 }

label {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	/* margin-bottom: 21px; */
}

label input{
	min-height: 24px;
}


select{
	height: 40px;
}


::-webkit-scrollbar-track {
	background-color: #fff;
}
::-webkit-scrollbar {
	width: 7px; background: #fff;
}
::-webkit-scrollbar-thumb {
	background: #e2e2e2;
}
.modalMessage {
	position: absolute;
	background-color: rgba(0,0,0,0.3);
	width: 100%;
	height: 100%;
	display: none;
	justify-content: center;
	align-items: center;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.body-modalMessage{
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 3px;
	background-color: #fff;
	box-shadow: 1px 1px 10px rgba(0,0,0,0.2);
	width: 25%;
	height: 25%;
}
.login {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	z-index: 1;
	overflow: hidden;
}

.form-login{
	display: flex;
	flex-direction: column;
	justify-content: center; 
	align-items: center;
	background-color: #fff;
	border-left: 5px solid #2196f3;
	border-radius: 3px;
	height: 300px;
	width: 43%;
	box-shadow: 1px 1px 23px rgba(0,0,0,0.1);
}

.content-login{
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 150px;
	width: 85%;
}

.info-login {
	display: flex;
	flex-direction: column;
	width: 67%;
}

.content-login label{
	width: 92%;
}

.content-login input{
	width: 100%;
}

.img-login{
	width: 120px;
	height: 120px;
}

.img-login img{
	width: 100%;
	height: 100%;
}

.background-login-operator, .background-login-customer {
	top: -60px;
	left: -30px;
	height: 50vh;
	width: 110vw;
	background-position: center;
	background-size: cover;
	position: absolute;
	transform: rotate(-4deg);
	z-index: -1;
	-webkit-filter: blur(3px);
	        filter: blur(3px)
}

.background-login-operator{
	background-image: url(/static/media/background.fbe16d6b.jpg);
}

.label-userType{
	z-index: 1;
	top: 100px;
	left: 30px;
	position: fixed;
	font-size: 10em;
	color: #FFF;
	opacity: 0.3;
}

.box-login {
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 8px 0px;
	flex-direction: column;
	align-items: center
}

.divBtn-login {
	background-color: #2196f3;
    cursor: pointer;
	color: #FFF;
	border-radius: 9px;
	width: 132px;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	margin-top: 20px;
	height: 32px;
	margin: 5px;
	display: flex;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.divBtn-login-blocked {
    cursor: pointer;
	color: #FFF;
	border-radius: 9px;
	width: 132px;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	margin-top: 20px;
	height: 32px;
	margin: 5px;
	display: flex;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	background-color: rgb(173, 173, 173)
}

.divBtn-login:active{
	background-color: #2378be
}

.divBtn-login a, .divBtn-signup a {
	text-decoration: none;
	margin-top: 8px
}

.divBtn-signup a:visited {
	text-decoration: none;
	color: #2196f3;
}

.divBtn-signup {
    cursor: pointer;
	color: #2196f3;
	align-items: center;
	display: flex;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.divBtn-login-operator{
    cursor: pointer;
	color: #FFF;
	font-weight: bold;
	text-transform: uppercase;
	margin: 5px;
	position: fixed;
	top: 8px;
	right: 8px
}

.divBtn-login-operator:hover{
	cursor: pointer;
	color: #FFF;
	transform: scale(1.1)
}

.divBtn-login-customer{
    cursor: pointer;
	color: #FFF;
	font-weight: bold;
	text-transform: uppercase;
	margin: 5px;
	position: fixed;
	top: 8px;
	right: 8px
}

.divBtn-login-customer:hover{
	cursor: pointer;
	color: #FFF;
	transform: scale(1.1)
}

.btn-login .link-login{
	color:white;
	text-decoration: none;
}

.div-error{
	height: 24px;
}

.error-user{
	display: none;
	color: #ff0000;
	text-align: center;
	font-size: 16px;
	padding: 3px;
	
}

.teste {
	display: block;
}


@media (max-width: 1000px) {
	.form-login{
		width: 75%;
	}
}

@media (max-width: 500px) {
	.form-login{
		width: 90%;
		height: auto;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.content-login {
		height: auto;
		width: 100%;
		flex-direction: column;
	}

	.info-login {
		width: 80%;
	}
}
.loadingMessage{
    flex: 1 1;
    position: absolute;
    display: flex !important;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: #8a8a8a;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none;
}

.loadingMessage h1{
    font-weight: 700;
    font-size: 16px;
    margin: 4px;
    text-align: center;
}

.loadingMessage h2{
    font-size: 14px;
}

.loadingMessage img{
    width: 60px;
    height: 60px;
    margin: 16px
}

.loading-img{
    align-self: center
}

.progress-bar-loading{
    background: linear-gradient(270deg ,#8200ff, #ff00ea, #ffcd00, #94ff00, #00d0ff,#8200ff);
    background-size: 1000% 1000%;
    -webkit-animation: backgroundAnimation 10s linear infinite forwards;
            animation: backgroundAnimation 10s linear infinite forwards;
    height: 8px;
    color: #fff;
    display: flex;
    align-items: center;
    border-radius: 10px;
    justify-content: center;
    font-size: 10px;
}

@-webkit-keyframes backgroundAnimation {
    0%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

@keyframes backgroundAnimation {
    0%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

.page-blocker{
    position: fixed;
    z-index: 99999999999999;
    background-color: rgba(255, 255, 255, 0.9);
    display: grid;
    place-items: center;
    top: 0px;
    bottom: 0;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    overflow: hidden;
    left: 0;
    right: 0;
    cursor: wait;
}
.register {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	z-index: 1;
	flex-direction: column
}

.steps{
	display: flex;
	align-items: center;
	border-bottom: 1px solid #CCCC;
	width: 100%;
}

.content-circles{
	margin: 16px;
	flex: 1 1;
}

.content-circles ul{
	display: flex;
}

.content-circles li{
	height: 39px;
    line-height: 39px;
	width: 39px;
	text-align: center;
	color: white;
	margin: 0 4px;
	border-radius: 100%
}

.form-register{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	border-left: 5px solid #2196f3;
	border-radius: 3px;
	height: 60%;
	width: 43%;
	box-shadow: 1px 1px 23px rgba(0,0,0,0.1);
	margin: .4px
}

.content-register{
	display: flex;
	justify-content: center;
	align-items: baseline;
	width: 95%;
}

.customer-form{
	display: flex;
	flex-wrap: wrap;
	flex: 1 1
}

.info-register {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
}

.info-register div {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.content-register label{
	width: 45%;
	margin: 7px;
}

.img-register{
	width: 120px;
	height: 120px;
}

.img-register img{
	width: 100%;
	height: 100%;
	box-shadow: 1px 1px 2px rgba(0,0,0,0.1);
}

.background-register {
	top: -60px;
	left: -30px;
	height: 50vh;
	width: 110vw;
	background-image: url(/static/media/background.fbe16d6b.jpg);
	background-position: center;
	background-size: cover;
	position: absolute;
	transform: rotate(-4deg);
	z-index: -1;
}
.activeStep {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  text-align: center;
  line-height: 2em;
  align-items: baseline;
  background: dodgerblue;
  display: inline-block;
  color: white;
  position: relative;
}

.notPassedStep{
	background-color: lightblue;
	color: dodgerblue!important;
}

.passedStep{
	background-color: dodgerblue;
}

.activeStep {
  background: dodgerblue;
}

.buttons{
	display: flex;
	justify-content: space-between;
	padding-bottom: 16px;
	padding-top: 16px;
}
/* 
.select{
	background-color: #ffff;
	border-radius: 3px;
	border: solid 1px #ccc;
	margin-right: -7px
} */

@media (max-width: 1000px) {
	.form-register{
		width: 75%;
	}

	.steps{
		width: 75%;
	}
}

@media (max-width: 500px) {
	.form-register{
		width: 85%;
		height: auto;
		padding-top: 10px;
		margin-top: 20px;
		margin-bottom: 20px;
		padding-bottom: 10px;
	}

	.content-register {
		height: auto;
		width: 100%;
		flex-direction: column;
	}

	.info-register {
		width: 80%;
	}

	.content-register label {
		width: 90%;
	}

	.register{
		height: auto;
		padding-top: 15px; 
	}
}
.normal-button.invert:active, .normal-button {
  height: 32px;
  background-color: #2196f3;
  color: #FFF;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: solid 1px #FFF;
  /* text-transform: uppercase; */
  transition: 0.5s; }

.has-icon svg {
  margin-left: 8px; }

.normal-button:active, .normal-button.invert {
  background-color: transparent;
  color: #1976D2;
  border: #1976D2 1px solid;
  border-radius: 5px; }

.normal-button.invert:active {
  border: solid 1px #FFF; }

.text-only {
  border: 1px solid transparent;
  background: transparent;
  color: #2196f3;
  box-shadow: none !important;
  transition: .5s;
  padding-left: 0;
  padding-right: 0; }

.text-only:active {
  border: 1px solid transparent; }

.normal-button.disabled {
  background-color: #ccc;
  box-shadow: none !important;
  transition: 0.5s; }

.normal-button.invert.disabled {
  color: #ccc;
  background-color: transparent;
  border-color: #ccc;
  box-shadow: none !important;
  transition: 0.5s; }

.text-only.disabled {
  color: #ccc;
  background-color: transparent;
  box-shadow: none !important;
  transition: 0.5s; }

.text-only:active {
  font-size: 13px;
  transition: .5s; }

.normal-button span {
  height: 32px;
  width: 32px;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  margin-right: -4px; }

.normal-button.small {
  max-width: 100px;
  align-self: flex-end;
  float: right;
  margin-top: 32px;
  margin-right: -12px; }

.normal-button.small-button {
  max-width: 100px;
  min-width: 64px; }

.shine-button {
  box-shadow: 0px 3px 10px #2196f3;
  border: none;
  transition: .5s; }

.shine-button:active {
  box-shadow: none; }

.span-date {
  display: flex;
  position: relative; }

.textInput {
  flex: 1 1; }

.title {
  flex: 1 1;
  width: 100%;
  align-self: center; }

.title .label {
  padding: 4px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .title .label h3 {
    font-weight: 100;
    color: #8a8a8a;
    font-size: 12px; }

.emoji button {
  width: 42px; }

.select-country-box{
	background-color: #ffff;
	border-radius: 3px;
	border: solid 1px #ccc;
	margin-right: 0px
}
.rankingCard {
  display: flex;
  border-radius: 3px;
  min-width: 230px;
  margin: 4px;
  min-height: 300px;
  position: relative;
  flex: 1 1; }

.rankingCard.shadow {
  box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.3);
  background-color: #FFF; }

.rankingCard.border {
  border: solid 2px #eee; }

.rankingCard .tip {
  background-color: #f2f2f2;
  text-align: center;
  padding: 16px;
  position: absolute;
  right: 0px;
  top: 0px;
  left: 0;
  bottom: 0;
  z-index: 9999;
  color: #6d6d6d;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.3s; }

.rankingCard-list {
  display: flex;
  flex: 1 1;
  align-items: baseline;
  width: 100%;
  justify-content: space-between; }

.rankingCard-list ul {
  flex: 1 1; }

.rankingCard-list li {
  display: flex;
  flex: 1 1;
  padding: 8px;
  position: relative;
  justify-content: center;
  align-items: center; }

.rankingCard-list ul li:nth-child(odd) {
  border-bottom: solid 1px #f2f2f2;
  border-top: solid 1px #f2f2f2; }

.rankingCard-list li:hover .tip {
  opacity: 1;
  transition: 0.3s; }

.ranking-things {
  display: flex; }

.rankingCard-item {
  display: flex;
  flex-direction: column;
  padding: 0 5px 0 5px;
  font-size: 12px;
  flex: 1 1;
  justify-content: center;
  padding: 0 16px; }

.rankingCard-item h1 {
  font-weight: bold; }

.rankingCard-item h2 {
  color: #babaca;
  font-weight: bold; }

.rankingCard-icons-align {
  display: flex;
  flex-direction: column; }

.rankingCard-img-align {
  display: flex;
  align-items: center; }

.rankingCard-img-align img {
  padding-right: 4px; }

.ranking-things p {
  height: 39px;
  line-height: 39px;
  width: 39px;
  text-align: center;
  color: dodgerblue;
  font-weight: bold;
  background-color: #C3DEF8;
  border-radius: 100%; }

.ColorEmptyMessage {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center; }

.emptyMessage {
  flex: 1 1;
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  color: #8a8a8a;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  text-align: center; }

.emptyMessage h1 {
  font-weight: 700;
  font-size: 20px;
  margin: 8px 16px 4px 16px; }

.emptyMessage h2 {
  font-size: 18px;
  padding: 0 16px; }

.ColorEmptyMessage {
  color: #2196f3;
  text-align: center;
  border-radius: 5px;
  background-color: #d5edff;
  border: dashed 1px #2196f3;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  flex: 1 1;
  padding: 16px;
  padding-bottom: 32px; }
  .ColorEmptyMessage svg, .ColorEmptyMessage path, .ColorEmptyMessage circle {
    margin: 16px; }
  .ColorEmptyMessage h1 {
    padding: 8px !important;
    font-weight: 700;
    font-size: 1.2em; }

.kpi-info, .ranking-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 500ms;
  text-align: center; }
  .kpi-info .value-container, .ranking-info .value-container {
    display: flex;
    align-items: center; }
    .kpi-info .value-container .label, .ranking-info .value-container .label {
      height: 8px;
      width: 8px;
      border-radius: 8px;
      margin-right: 8px; }
  .kpi-info .value, .ranking-info .value {
    font-weight: 700;
    font-size: 26px;
    padding: 0px 0px; }
  .kpi-info h2, .ranking-info h2 {
    color: #707070;
    font-size: 12px;
    text-transform: capitalize; }
  .kpi-info .down, .ranking-info .down {
    color: #ec6b56;
    transition: 500ms; }
    .kpi-info .down svg, .ranking-info .down svg {
      transform: rotate(127deg);
      transition: 500ms; }
  .kpi-info .up, .ranking-info .up {
    color: #2AC940;
    transition: 500ms; }
    .kpi-info .up svg, .ranking-info .up svg {
      transform: rotate(45deg);
      transition: 500ms; }
  .kpi-info .new, .ranking-info .new {
    color: #fd9d02; }
  .kpi-info .same, .ranking-info .same {
    color: #339AFF; }
    .kpi-info .same svg, .ranking-info .same svg {
      transform: rotate(90deg);
      transition: 500ms; }
  .kpi-info .past, .ranking-info .past {
    color: #8a8a8a;
    text-transform: lowercase; }
  .kpi-info .comparative, .ranking-info .comparative {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px; }
    .kpi-info .comparative svg, .ranking-info .comparative svg {
      margin-right: 4px; }

.ranking-things .value {
  font-size: 16px; }

.ranking-things .comparative {
  margin-top: 0px; }

.kpi-info {
  flex: 1 1; }

.ranking-info {
  padding: 0px 16px;
  min-width: 62px;
  min-height: 51px; }

.__react_component_tooltip {
  text-transform: lowercase; }


.card {
  display: flex;
  flex-direction: column;
  transition: 500ms;
  max-width: 700;
  transition: 500ms; }
  .card .subtitle-gray {
    color: #8a8a8a; }
  .card header {
    padding: 16px;
    border-bottom: solid 1px #f2f2f2;
    display: flex; }
    .card header svg:active {
      transform: scale(0.9); }
  .card main {
    position: relative;
    flex: 1 1;
    display: flex;
    flex-direction: column; }
  .card footer {
    display: flex;
    flex-direction: row-reverse; }
    .card footer h2 {
      color: #8a8a8a; }
  .card .normal-button.text-only {
    margin-bottom: -8px;
    margin-right: -16px; }

.home{
	flex: 1 1;
	display: flex;
}

.home-row{
	display: flex;
	flex-direction: row;
	flex: 1 1;
}

.home-column{
	display: flex;
	flex-direction: column;
	flex: 1 1;
	padding: 16px;
	flex-wrap: wrap;
}

.operator-row{
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
}

.operator-column{
	display: flex;
	flex: 1 1;
	flex-direction: column;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}
.piechartContainer {
  display: flex;
  margin: 4px;
  border-radius: 3px;
  box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.3);
  background-color: #FFF;
  flex-direction: column;
  padding-bottom: 16px;
  box-sizing: border-box; }
  .piechartContainer .info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }

.pieChartTitle h1 {
  font-weight: 700;
  padding-top: 15px;
  padding-bottom: 16px;
  padding-left: 16px;
  border-bottom: solid 1px #f2f2f2; }

.piechartContent {
  position: relative;
  flex: 1 1;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  display: flex;
  align-self: center; }
  .piechartContent .info {
    position: absolute; }
  .piechartContent .value {
    font-size: 26px;
    font-weight: 700; }
  .piechartContent .value-description {
    color: #ccc; }


.tooltip-container {
  min-width: 100px;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 16px;
  display: flex;
  flex-direction: column; }
  .tooltip-container h1 {
    font-weight: 600; }
    .tooltip-container h1 span {
      font-weight: 700; }
  .tooltip-container h2 {
    color: #8a8a8a;
    font-size: 12px;
    padding-top: 4px; }
  .tooltip-container ul {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 8px; }
  .tooltip-container li {
    display: flex;
    align-items: center;
    height: 20px; }
    .tooltip-container li h1 {
      font-weight: 100; }
  .tooltip-container .subtitle {
    height: 8px;
    width: 8px;
    border-radius: 4px;
    margin-right: 4px; }


.SwitchList{
    background-color: #fff;
    min-height: 30px;
    padding: 4px;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.3);
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.SwitchList.flat{
    box-shadow: none;
    border: 1px solid #ccc;
}

.SwitchList .SwitchBackground{
    border-radius: 3px;
    position: absolute;
    height: calc(100% - 8px);
    z-index: 1;
    transition: .4s ease-in-out ;
}
.SwitchList div{
    display: flex;
    flex: 1 1;
    position: relative;
    z-index: 2;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.SwitchList div p{
    cursor: pointer;
    transition: .4s ease-in-out ;
}
.dropdownfilter{
    background-color: #FFF;
    height: 38px;
    border-radius: 3px;
    min-width: 150px;
    max-width: 180px;
    transition: 0.5s;
    position: relative;
    box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.3);
    z-index: 2;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.dropdownfilter.border{
    border: 1px solid #ccc;
    box-shadow: none;
    border-radius: 3px
}

.input-title{
    /* font-weight: 700; */
    padding-bottom: 8px
}

.dropdownfilter .display{
    display: flex;
    padding: 4px;
    align-items: center;
    justify-content: center
}

.dropdownfilter .display h1{
    background-color: #FFF;
    padding: 8px;
    border-radius: 2px;
    width: 125px
}

.dropdownfilter svg{
    padding: 0px 4px
}

.dropdownfilter:hover{
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px
}

.dropdownfilter:hover > .filterlist{
    max-height: 900px;
    transition: max-height 0.5s;
    transition-timing-function: ease-in-out;
}

.dropdownfilter .filterlist{
    width: 177px;
    position: absolute;
    background-color: #FFF;
    max-height: 0px;
    transition: max-height 0.5s;
    transition-timing-function: ease-in-out;
    overflow: hidden;
    box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
    margin-top: 8px;
    border-radius: 3px;
    box-sizing: border-box
}

.dropdownfilter .filterlist li{
    padding: 8px;
    margin: 4px
}

.dropdownfilter .filterlist li:hover{
    background-color: #2196f3;
    color: #FFF;
    border-radius: 3px;
}

.row{
    display: flex;
    align-items: center
}

.toggle-text{
    padding-right: 4px;
    font-size: 12px
}

.toggle{
  height: 36px;
  width: 76px;
  background-Color: #ccc;
  display: flex;
  justify-content: space-around;
  align-Items: center;
  color: #FFF;
  border-radius: 42px;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: 0.5s;
}

.toggle.small{
    height: 22px;
    width: 43px;
    border-radius: 22px;
}

.toggle.small .switcher{
    height: 18px;
    width: 18px;
    left: 2px
}


.toggle.small.right .switcher{
  left: 23px;
  box-shadow: -3px 1px 5px rgba(0,0,0,0.2);
  transition: 0.5s;
  transition-timing-function: steps-start;
}

.toggle .switcher{
  height: 29px;
  width: 29px;
  background-color: #FFF;
  border-radius: 42px;
  position: absolute;
  left: 4px;
  box-shadow: 3px 1px 5px rgba(0,0,0,0.3);
  transition: 0.5s;
  transition-timing-function: steps-start;
}

.toggle.right .switcher{
  left: 43px;
  box-shadow: -3px 1px 5px rgba(0,0,0,0.2);
  transition: 0.5s;
  transition-timing-function: steps-start;
}

.toggle.right{
    background-Color: #2196f3;
    transition: 0.5s;
}

.toggle h1{
  z-index: 99;
}

.toggle.right h1:nth-child(2), .toggle.left h1:nth-child(1){
  color: #2196f3;
  transition: 0.5s;
  font-weight: 700
}

.square-toggle{
	width: 50%;
	position: absolute;
	height: inherit;
	display: flex;
	box-sizing: border-box;
}

.square-toggle p{
	margin: auto;
}

.square-toggle:nth-child(1) {
	right: 0;
}

.square-inner-container {
	position: absolute;
	left: 0;
	top: 0;
	width: inherit;
	height: inherit;
	text-transform: uppercase;
	font-size: .6em;
	letter-spacing: .2em;
}

.square-inner-container:first-child {
	color: #8a8a8a;
}

.square-inner-container:nth-child(2){
	background: dodgerblue;
	color: white;
	-webkit-clip-path: inset(0 50% 0 0);
	        clip-path: inset(0 50% 0 0);
	transition: .3s cubic-bezier(0,0,0,1);
}

.square-container {
	width: 90px;
	height: 38px;
	margin: auto;
	position: relative;
	border-radius: 3px;
	overflow: hidden;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
  cursor: pointer;
  border: solid 1px #CCC
}

.moving-square{
	height: 100%;
	width: 50%;
	background-color: #2196f3;
	position: absolute;
  transition: 500ms;
  box-shadow: 2px 0px 5px rgba(0,0,0,0.2)
}

.right .moving-square{
	transform: translateX(100%);
  transition: 500ms;
  box-shadow: -2px 0px 5px rgba(0,0,0,0.2)
}

.square-container p{
	z-index: 2;
	font-size: 12px;
	font-weight: 700
}

.white{
	color: #FFF;
	transition: 500ms;
	font-size: 14px
}
.DatePicker{
    min-height: 200px;
    display: flex;
    align-content: center;
    align-items: center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    min-width: 310px;
}

.DatePicker-Calendar{
    flex: 1 1;
    min-height: 200px;
    max-width: 360px;
    align-content: center;
    justify-content: center;
    display: flex;
    flex-direction: column
}

.DatePicker-Calendar .weekLabel:hover, .DatePicker-Calendar .weekLabel.active{
    background-color: #2196f3;
    color: #FFF
}

.weekLabel-exception{
	background-color: #0F9F4F;
    border-top-left-radius: 21px;
    border-top-right-radius: 21px;
    color: #f2f2f2;
	margin: 0 -4px;
}

.DatePicker-Calendar .selector{
    background-color: #2196f3;
    display: flex;
    justify-content: space-between;
    min-height: 28px;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: #fff
}

.DatePicker-Calendar .selector .button{
    background-color: #1976D2;
    width: 32px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 32px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    text-align: center
}

.DatePicker-Calendar .selector .monthLabel{
    flex: 1 1;
    text-align: center
}


.DatePicker-Calendar ul{
    display: grid;
    grid-template-columns: repeat(7,1fr);
    grid-column-gap: 8px;
    padding: 8px;
    border: 1px solid #ccc;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.DatePicker-Calendar ul li{
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    min-height: 30px
}

.DatePicker-Calendar ul li.day:hover, .DatePicker-Calendar ul li.day.active, .DatePicker-Calendar .weekLabel-exception:hover{
    background-color: #037B39;
    color: #FFF
}

.DatePicker-Calendar ul li.day.start-day{
    background-color: #7BBB4C;
    color: #2a4303;
	border-top-left-radius: 21px;
	border-bottom-left-radius: 21px;
	margin: 0 -4px;
}

.DatePicker-Calendar ul li.day.end-day{
    background-color: #E14B46;
	border-top-right-radius: 21px;
	border-bottom-right-radius: 21px;
    color: #450405;
	margin: 0 -4px;
	position: relative;
}

.DatePicker-Calendar ul li.day.between-days{
    background-color: #d5edff;
	margin: 0 -4px;
	color: #2196f3;
	position: relative;
}

.DatePicker-Calendar ul li.day.between-days.exception{
    background-color: #f2f2f2;
	color: #8a8a8a
}

.DatePicker-Calendar ul li.day.between-days.exception:hover{
    background-color: #b4b4b4;
	color: #8a8a8a
}

.DatePicker-Calendar ul li.day.between-days.exception::after{
	content: '';
	width: 20px;
	height: 1px;
	background: #8a8a8a;
	position: absolute;
	transform: rotate(-45deg)
}

.DatePicker-Calendar ul li.day.between-days:hover{
	background-color: #2196f3;
	color: #FFF;
    border-radius: 0
}

.DatePicker-Calendar ul li.day.same-day{
    background-image: linear-gradient(to bottom right, #7BBB4C 50%, #D32F2F 50%);
	margin: 0 -4px;
	border-radius: 21px;
	color: #FFF
}

.DatePicker-Calendar ul li.exception-day{
    background-color: #05A24B;
    color: #FFF;
	margin: 0 -4px;
}

.DatePicker-Calendar ul li.day.exception-day:hover{
    border-radius: 0;
	background-color: #037B39;
}

.top-left{
    border-top-left-radius: 3px
}

.DatePicker-Calendar ul li.day.between-days:nth-child(14){
    border-top-right-radius: 3px
}

.DatePicker-Calendar ul li.day.between-days:last-child{
    border-bottom-right-radius: 3px
}

.ColorList{
    flex: 1 1;
    display: flex;
    min-width: 500px; 
    border-radius: 5px;
	flex-direction: column;
	border: solid 1px #ccc;
}

@media only screen and (max-width: 1201px) {
    .ColorList {
        margin-top: 16px;
    }
}

.colorList-title{
    padding-bottom: 9px;
	border-bottom: solid 1px #ccc;
}

.ColorList-content{
    flex: 1 1;
    padding: 10px;
    flex-wrap: wrap;
    overflow-y: scroll;
    display: flex
}

.ColorList-content ul{
	display: flex;
    flex-wrap: wrap;
    flex: 1 1;
    align-content: flex-start;
    align-items: flex-start
}

.colorList-text{
    font-size: 14px;
 	padding-right: 4px;
    margin: 8px -10px 0px 12px;
}

.ColorListItem > div{
    flex: 1 1;
    display: flex;
    flex-direction: column
}

.ColorListItem > div >div{
    flex: 1 1;
    display: flex;
    align-items: center
}

.ColorPicker-border{
    padding: 16px 8px;
	border-right: solid 6px #2196f3;
}

.name-item-rule{
    font-weight: bold;
}

.hour-item-rule{
	font-size: 12px;
	margin-top: 6px;
    color: #757575;
}

.ColorList-popup{
    flex: 1 1;
    border: none;
    z-index: 999;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    min-height: 500px;
    border-radius: 5px;
    align-content: flex-start;
    align-items: flex-start
}

.ColorList-popup-title{
    font-weight: 700;
    margin: 16px 16px 0px 16px
}

.ColorListItem{
    flex: 1 1;
    margin: 4px;
    display: flex;
    padding: 12px;
    min-height: 45px;
    min-width: 132px;
    max-width: 143px;
    position: relative;
    border-radius: 3px;
    background-color:#f2f2f2;
    justify-content: space-between;
}

.ColorList .title{
    font-weight: 700;
    text-transform: capitalize
}

.ColorList .subtitle{
    color: #8a8a8a
}

.ColorList-popup li div{
	display: flex;
}

.ColorList-popup h2{
    padding: 4px;
    padding-left: 8px;
	font-weight: bold;
}

.link-fake {
    padding: 4px;
    cursor:pointer;
    margin-top: 16px;
    text-decoration:underline;
}

#color-list li:last-child{
	-webkit-animation: bounce 0.5s;
	        animation: bounce 0.5s
}

.ColorListItem .icon-bounce{
    transform: translate(12px, 12px)
}

.ColorListItem.disable{
    opacity: 0.5;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.emptyColorListMessage{
    flex: 1 1;
    color: #2196f3;
    text-align: center;
    border-radius: 5px;
    background-color: #d5edff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: dashed 1px #2196f3;
    padding: 8px
}

.emptyColorListMessage h1{
    font-weight: 700;
    font-size: 14px;
    padding: 4px
}

.emptyColorListMessage h2{
    font-size: 12px;
    font-weight: 100;
    padding: 4px
}
.tabContent{
  display: flex;
  flex: 1 1;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
  flex-wrap: wrap;
  overflow-y: scroll;
}

.tabContent .content{
  flex: 1 1;
  margin: 8px;
  flex-wrap: wrap;
}

.tabContent .content .bold{
  font-weight: 700;
}

.tabContent .content .gray{
  color: #8a8a8a;
}

.tabContent .row{
    display: flex;
    flex: 1 1;
    width: 100%;
    flex-wrap: wrap;
	  align-content: flex-start
}

.tabContent .row.right{
    justify-content: flex-end;
    margin-top: 16px
}

.tabContent .row.right button{
    margin: 4px
}

.datesContainer {
  display: table-row;
  justify-content: space-between;
}

.add-button{
  height: 21px;
  width: 21px;
}
.DateRangeArrow {
  position: absolute;
  top: 8px;
  padding: 8px;
  border-radius: 50%; }

.DateRangeArrow:hover {
  background-color: #f2f2f2; }

.DateRangeArrow:active {
  transform: scale(0.9); }

.CalendarDay__default {
  vertical-align: middle; }

.CalendarDay__selected_span {
  background: #d5edff;
  border: 1px double #2196f3;
  color: #2196f3; }

.CalendarDay__selected_span:hover, .CalendarDay__selected, .CalendarDay__selected:hover {
  background-color: #2196f3;
  color: #fff; }

.DateInput_input__focused {
  border-color: #2196f3; }

.datesContainer {
  padding-left: 4px; }

.datePickerLabel {
  padding-bottom: 4px; }

.DateRangePickerInput__withBorder {
  border: 1px solid #ccc;
  height: 38px;
  border-radius: 3px; }

.selectableItem{
	margin: 16px;
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    border-radius: 5px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    position: relative;
    padding: 16px;
    /* box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.3); */
    /* border: 1px #ccc solid;
    border-radius: 5px; */
    border-radius: 8px;
}

.body-itens{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.selectableItem .img{
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	width: 90px;
	height: 90px;
	border-radius: 100%;
    border: 1px solid #FFF;
/*	border: 3px solid #2196f3;*/
	/* position: relative; */
	cursor: pointer;
    background-color: #fff;
    padding: 4px;
    box-sizing: border-box;
}

.selectableItem .img.selected{
    border-color: #2196f3;

}

.img.selected-cant-unselected img{
    -webkit-filter: saturate(0);
    cursor: not-allowed;
}


.img.selected-cant-unselected{
    border-color: #babaca;
    background-color: #f2f2f2!important
}

.img.selected-cant-unselected > .name-logo{
    color: #babaca;
    background-color: #f2f2f2!important
}

.loading{
	display: none;
}

.selectableItem .img img {
	width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover
}

.selectableItem .tip{
	background-color: #2196f3;
    color: #fff;
    padding: 4px;
	border-radius: 5px;
	text-align: center;
    margin: 4px;
    margin-top: 12px;
}

.selectableItem .tip p {
    text-align: center;
    font-size: 12px;
    
}

.selectableItem .tip:hover ::after {
    padding: 4px;
    border-radius: 5px;
    background-color: #000;
    position: absolute;
    right: -36px;
    top: 2px;
    opacity: 0.4;
}


.selectableItem .description {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
    text-align: center;
    -webkit-user-select: text;
       -moz-user-select: text;
        -ms-user-select: text;
            user-select: text
}

.selectableItem .description h2{
	font-weight: bold;
    padding: 8px 0;
    text-align: center;
    font-size: 14px;
}

.selectableItem .description p{
	color: #8a8a8a
}

.selectableItem .actions{
    margin-top: 16px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: 25px;
}
.selectableItem .upper-actions{
    position: absolute;
    top: -16px
}

.selectableItem .actions > svg:hover{
    transform: scale(1.1);
    transform-origin: center;
    transition: 0.5s
}

.selectableItem .actions > svg{
    margin-right: 4px;
}

.selectableItem .interest{
    color: #2196f3!important;
    padding-top: 8px;
}

.indent {
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
}
.reach-visualization-container {
  width: 400px; }
  .reach-visualization-container .title {
    font-weight: 700; }
  .reach-visualization-container .reach-value {
    font-size: 31px;
    color: #2196f3;
    text-align: center;
    font-weight: 700; }
  .reach-visualization-container .reach-container {
    margin: 64px 0px  83px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .reach-visualization-container .select-all {
    display: flex;
    flex-direction: row;
    align-items: center; }
  .reach-visualization-container .segmentation-action-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end; }

.segmentationLabel {
  padding: 16px 0px; }
  .segmentationLabel span {
    color: #2196f3;
    font-weight: 700; }

.informations {
  display: flex;
  max-width: 480px;
  margin-right: 32px; }

.tabScreen-content {
  flex: 1 1;
  padding: 16px 24px;
  display: flex; }
  .tabScreen-content .content {
    flex: 1 1; }
  .tabScreen-content .informations {
    flex: 1 1;
    flex-wrap: wrap; }

.tabScreen-container {
  box-shadow: 3px -3px 25px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  flex: 1 1;
  background-color: #FFF; }

.tabScreen-header {
  display: flex;
  padding: 16px; }
  .tabScreen-header a.activeTab {
    font-weight: bold;
    color: black;
    border-bottom: 3px solid #2196f3; }

.tabScreen-routes {
  flex: 1 1;
  display: flex; }


.addComboListView {
  width: 500px;
  position: relative; }
  .addComboListView .title {
    font-weight: 700;
    padding-bottom: 8px; }
  .addComboListView .subtitle {
    padding: 16px 0px;
    font-weight: 700; }
  .addComboListView .border {
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 8px; }
  .addComboListView .comboContainer {
    display: flex;
    margin: -9px -9px 8px -9px; }
    .addComboListView .comboContainer select {
      flex: 1 1;
      margin: 0;
      border-radius: 0px; }
      .addComboListView .comboContainer select option {
        padding: 5px;
        min-height: 200px; }
  .addComboListView .add-button {
    background-color: #2196f3;
    margin: 0;
    color: #FFF;
    min-height: 40px;
    width: 40px;
    border: none;
    border-radius: 0px;
    padding: 16px; }

.input-error {
  color: #ff5f58;
  border: solid 1px #ff5f58;
  -webkit-animation: shake 0.3s;
          animation: shake 0.3s; }

.input-error::-webkit-input-placeholder {
  color: #ff5f58; }

.input-error::-ms-input-placeholder {
  color: #ff5f58; }

.input-error::placeholder {
  color: #ff5f58; }

.shake {
  -webkit-animation: shake 0.3s;
          animation: shake 0.3s; }

@-webkit-keyframes shake {
  0% {
    transform: translateX(8px); }
  25% {
    transform: translateX(-8px); }
  50% {
    transform: translateX(8px); }
  75% {
    transform: translateX(-8px); }
  100% {
    transform: translateX(0px); } }

@keyframes shake {
  0% {
    transform: translateX(8px); }
  25% {
    transform: translateX(-8px); }
  50% {
    transform: translateX(8px); }
  75% {
    transform: translateX(-8px); }
  100% {
    transform: translateX(0px); } }

#search-search-container {
  flex: 1 1;
  margin: -4px;
  display: flex;
  margin-right: 0;
  position: relative;
  flex-direction: column;
  padding: 16px;
  background-color: #fbfcfd;
  scroll-behavior: smooth; }

#search-info-container {
  min-width: 284px;
  max-width: 284px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  #search-info-container .row {
    display: flex;
    justify-content: space-between; }

#search-list-container {
  flex: 1 1;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: start;
  margin-top: 16px;
  border-radius: 5px; }

#search-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -16px;
  position: relative; }
  #search-footer img {
    height: 42px;
    width: 42px; }

.popup-trigger {
  color: #2196F4;
  transition: .5s; }

.popup-trigger:hover {
  transform: scale(0.9); }

.popup-trigger:active {
  transform: scale(0.8); }

.popup-container .title {
  font-weight: 700;
  flex: none; }

#search-search-container {
  flex: 1 1;
  margin: -4px;
  display: flex;
  margin-right: 0;
  position: relative;
  flex-direction: column;
  padding: 16px;
  background-color: #fbfcfd;
  scroll-behavior: smooth; }

#search-info-container {
  min-width: 284px;
  max-width: 284px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  #search-info-container .row {
    display: flex;
    justify-content: space-between; }

#search-list-container {
  flex: 1 1;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: start;
  margin-top: 16px;
  border-radius: 5px; }

#search-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -16px;
  position: relative; }
  #search-footer img {
    height: 42px;
    width: 42px; }

.tag-select-container {
  flex: 1 1;
  position: relative;
  display: contents; }
  .tag-select-container .options-container {
    background-color: #fff;
    border-radius: 3px;
    transition: 0.5s;
    box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.3);
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    margin-top: 4px;
    overflow: scroll;
    padding: 8px; }
    .tag-select-container .options-container .tag-option {
      padding: 8px;
      margin-top: 4px;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }
    .tag-select-container .options-container .tag-option:hover {
      background-color: #f2f2f2;
      border-radius: 3px; }
    .tag-select-container .options-container .section-title {
      color: #aeaeae;
      padding: 4px 0px; }

.tag-select-content {
  border: 1px solid #ccc;
  padding: 8px;
  background-color: #FFF;
  border-radius: 3px;
  align-items: center;
  flex-wrap: wrap;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: flex; }
  .tag-select-content .selected-tag {
    background-color: #f2f2f2;
    padding: 8px;
    border-radius: 3px;
    color: #ccc;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    margin-right: 2px;
    margin-bottom: 2px;
    display: flex;
    font-size: 12px; }
    .tag-select-content .selected-tag h1 {
      font-weight: 700; }
  .tag-select-content .selected-tag .delete-action {
    content: 'x';
    display: flex;
    top: 4px;
    right: 4px;
    padding-left: 16px;
    margin-right: -8px;
    margin-top: -8px;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
    margin-bottom: -8px; }
  .tag-select-content input {
    margin: 0;
    border: none;
    flex: 1 1;
    min-width: 100px; }

.tag-option-container {
  font-size: 12px;
  display: flex;
  align-items: center; }
  .tag-option-container h1 {
    font-weight: 700; }

.avatar-pic {
  border-radius: 100%;
  overflow: hidden;
  min-height: 80px;
  min-width: 80px;
  max-width: 80px;
  max-height: 80px;
  align-self: center;
  border: 5px solid #fff;
  background-color: #FFF;
  box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; }

.name-logo h1 {
  font-weight: 100 !important; }

.background-picture.small {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  transform: scale(0.4); }

.avatar-pic img {
  width: 100%;
  height: 100%; }

.avatar-pic .edit-picture {
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  color: rgba(255, 255, 255, 0.8);
  width: 100%;
  text-align: center;
  padding: 8px 0;
  font-size: 12px;
  display: none;
  cursor: pointer; }

.avatar-pic:hover .edit-picture {
  display: inline; }

.edit-picture:hover {
  color: #FFF; }

.product-item-range {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px; }
  .product-item-range .title {
    font-weight: 700; }
  .product-item-range .subtitle {
    color: #8a8a8a;
    font-weight: 400;
    margin-bottom: 8px;
    padding: 0; }
  .product-item-range .ranges-container label {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .product-item-range .ranges-container label input {
      margin-right: 8px; }

.list-modal-style {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  background-color: #fbfcfd; }
  .list-modal-style .title {
    font-weight: 700;
    font-size: 16px;
    flex: none; }
  .list-modal-style .subtitle {
    color: #8a8a8a;
    flex: 0 1;
    padding-bottom: 16px; }
  .list-modal-style label {
    flex: 1 1; }
  .list-modal-style ul {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    width: 100%;
    padding: 8px 0px;
    max-height: 500px; }
    .list-modal-style ul .list-modal-list-item {
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 3px;
      margin-bottom: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 30px; }
      .list-modal-style ul .list-modal-list-item h1 {
        font-weight: 700;
        padding: 2px 0px; }
    .list-modal-style ul .list-modal-list-item.empty {
      color: #2196F4;
      background-color: #d5edff;
      border: 1px dashed #2196f3; }
    .list-modal-style ul .list-modal-list-item.loading {
      opacity: 0.5; }
  .list-modal-style footer {
    padding-top: 16px; }
    .list-modal-style footer .title {
      font-weight: 700;
      font-size: 14px; }
    .list-modal-style footer div {
      display: flex;
      align-items: flex-end;
      background-color: #fff;
      margin: -16px;
      padding: 16px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px; }
      .list-modal-style footer div select, .list-modal-style footer div label {
        margin: 0px; }
  .list-modal-style .action-icon {
    height: 21px;
    width: 21px;
    padding: 4px;
    transition: 0.5s; }
  .list-modal-style .action-icon:hover {
    -webkit-animation: bounce 500ms;
            animation: bounce 500ms; }
  .list-modal-style .action-icon:active {
    transform: scale(0.7);
    transition: 0.5s; }
  .list-modal-style .add-button {
    align-self: center;
    margin-bottom: -5px; }

.trigger-toggle {
  padding: 0px 8px;
  color: #2296F3;
  transition: 500ms; }

.trigger-toggle:active {
  padding: 0px 8px;
  color: #2296F3;
  transition: 500ms;
  transform: scale(0.8); }

.trigger-toggle:hover {
  color: #d5edff; }


.list-itensProduct{
	display: flex;
	flex-wrap: wrap;
    box-sizing: border-box;
	flex: 1 1;
	position: relative;
	align-content: start;
}

.itens-row{
	display: flex;
	flex: 1 1;
	align-items: flex-end;
	justify-content: flex-end;
}

.option-itens{
	display: flex;
	justify-items: center;
	justify-content: space-between;
    align-items: center;
}

.btn{
	height: 40px;
	width: 100px;
	margin-left: 20px;
	background-color: #fff;
	color: #1976d2;
	border: 1px solid #1976d2;
}

.newItem{
	background-color: #1976d2;
	color: #FFF;
	border: 1px solid #1976d2;
}

.delete{
	background-color: #fff;
	color: #1976d2;
	border: 1px solid #1976d2;
}

.options-bar{
	display: flex;
    justify-content: space-between;
    border-bottom: solid 1px #f1f1f1;
	height: 42px;
	padding: 16px 23px;
	position: relative;
}

.itens-content{
    flex: 1 1;
	display: flex;
	flex-direction: column;
	position: relative;
}


#search-search-container {
  flex: 1 1;
  margin: -4px;
  display: flex;
  margin-right: 0;
  position: relative;
  flex-direction: column;
  padding: 16px;
  background-color: #fbfcfd;
  scroll-behavior: smooth; }

#search-info-container {
  min-width: 284px;
  max-width: 284px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  #search-info-container .row {
    display: flex;
    justify-content: space-between; }

#search-list-container {
  flex: 1 1;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: start;
  margin-top: 16px;
  border-radius: 5px; }

#search-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -16px;
  position: relative; }
  #search-footer img {
    height: 42px;
    width: 42px; }

.image-picker-container {
  max-height: 100px;
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d5edff;
  color: #2196f3;
  border: 1px dashed #2196f3;
  text-align: center;
  padding: 16px;
  border-radius: 5px;
  position: relative;
  overflow: hidden; }

.image-picker-container:hover > #image-picker-remove {
  opacity: 1; }

.image-picker-container:hover > #image-picker-label {
  -webkit-filter: blur(25px);
          filter: blur(25px); }

#image-picker-label {
  height: 100%;
  width: 100%;
  position: absolute;
  transition: 0.5s; }

#image-picker-remove {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: #FFF;
  background-color: rgba(0, 0, 0, 0.1);
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s;
  z-index: 999; }

.selected {
  background-color: #fff;
  border-color: #fff; }

.search-bar label {
  padding: 0; }

#see-more-indicator {
  width: 42px;
  height: 42px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.1);
  position: relative;
  -webkit-animation: zoom-in 0.5s;
          animation: zoom-in 0.5s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  #see-more-indicator .indicator {
    height: 16px;
    width: 16px;
    background-color: #2196f3;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 4px;
    position: absolute;
    top: -8px;
    right: -8px;
    font-size: 10px; }
  #see-more-indicator img {
    height: 100%;
    width: 100%;
    transform: translate(-1px); }

@-webkit-keyframes zoom-in {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes zoom-in {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

#search-search-container {
  flex: 1 1;
  margin: -4px;
  display: flex;
  margin-right: 0;
  position: relative;
  flex-direction: column;
  padding: 16px;
  background-color: #fbfcfd;
  scroll-behavior: smooth; }

#search-info-container {
  min-width: 284px;
  max-width: 284px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  #search-info-container .row {
    display: flex;
    justify-content: space-between; }

#search-list-container {
  flex: 1 1;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: start;
  margin-top: 16px;
  border-radius: 5px; }

#search-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -16px;
  position: relative; }
  #search-footer img {
    height: 42px;
    width: 42px; }

.loja-content {
	display: flex;
	flex-direction: column;
	height: 88%;
}

.loja-main{
	display: flex;
	flex-direction: column;
	flex: 1 1;
}

.loja-content input{
	align-self: center;
	width: 97%;
}

.title-loja {
	font-size: 21px;
	font-weight: bold;
	margin-top: 20px;
	margin-left: 20px;
}

.list-lojaProduct{
	display: flex;
	flex-wrap: wrap;
	padding-left: 20px;
	flex: 1 1;
	padding-right: 20px;
}

.option-loja{
	display: flex;
	justify-items: center;
	justify-content: space-between;
	}

.btn-newItem{
	height: 40px;
	width: 100px;
	margin-left: 20px;
	background-color: #fff;
	color: #1976d2;
	border: 1px solid #1976d2;
}

.option-itens{
	float: right;
	margin-right: 32px
}

.options-bar{
	display: flex;
    justify-content: space-between;
    border-bottom: solid 1px #f1f1f1;
	height: 42px;
	padding: 16px 23px
}
.statistic{
	position: relative;
	flex: 1 1
}

.statistic .content{
	display: flex;
	justify-content: space-between;
	padding: 12px;
	flex-wrap: wrap;
}
.chartContainer {
	display: flex;
	margin: 8px;
	border-radius: 3px;	
}

.chartContainer.shadow{
	box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.3);
	background-color: #FFF;
}

.chartContainer.border{
	border: solid 2px #eee
}

.chartContent{
	padding: 16px 32px 16px 0px;

}

.areaChart{
	flex-direction: column;
	display: flex;
	justify-content: center;
	align-items: center;
}

.chartContent h1{
	font-weight: 700;
	padding-bottom: 16px;
	padding-left: 16px;
	border-bottom: solid 1px #f2f2f2;
	margin-bottom: 32px;
	margin-right: -32px
}

.cardTabProduct {
	box-shadow: 3px -3px 25px rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    flex: 1 1;
    box-sizing: border-box;
    background-color: #FFF;
}

.cardTabProduct .header-cardTabProduct{
	display: flex;
	align-items: center;
	padding: 16px;
}
.option-cardTabProduct a{
	text-decoration: none;
	color: #636363;
	padding: 8px;
	cursor: pointer;
}

.option-cardTabProduct a.activeTab{
	font-weight: bold;
	color: black;
    border-bottom: 3px solid #2196f3;
}

.header-cardTabProduct .option-cardTabProduct.active{
	font-weight: bold;
	color: black;
	border-bottom: 3px solid #2196f3;
}

/* .route-promotion{
    box-sizing: border-box;
	display: flex;
	flex: 1;
} */

.cardTab-container{
	flex: 1 1;
	padding: 8px
}

.tab-content{
	padding: 16px 26px;
}

.tab-content .title{
	font-weight: 700;
}

/*

@media (max-width: 790px) {
	.cardTabProduct {
    	width: 99%;
    	padding-bottom: 20px;
	}
}
*/



.grid {
  border-radius: 5px;
  text-align: left;
  margin-bottom: 8px; }
  .grid .grid-table {
    flex: 1 1;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    position: relative;
    min-height: 300px;
    display: flex;
    flex-direction: column; }
  .grid .grid-header {
    font-weight: 700;
    display: flex; }
    .grid .grid-header th:hover {
      background-color: #f8f8f8; }
  .grid .grid-row {
    padding: 16px;
    border-radius: 30px;
    margin: 4px; }
  .grid .dropdownfilter .filterlist {
    z-index: 99999; }
  .grid .grid-body:hover {
    color: #2196f3;
    background-color: #d5edff !important;
    transition: 0.5s; }
  .grid tr {
    display: flex; }
  .grid th, .grid td {
    flex: 1 1;
    display: flex;
    align-items: center; }
  .grid .number {
    font-weight: 700;
    text-align: center; }
  .grid .grid-paginator {
    flex: 1 1;
    align-items: center;
    justify-content: flex-end;
    padding: 16px 0px;
    display: flex; }
    .grid .grid-paginator .back, .grid .grid-paginator .next {
      height: 21px;
      width: 21px;
      border-radius: 50%;
      background-image: url(/static/media/arrow.954f980c.svg);
      background-repeat: no-repeat;
      background-size: contain; }
    .grid .grid-paginator .back {
      transform: rotateY(180deg); }
  .grid .grid-content {
    scroll-behavior: smooth;
    overflow-y: scroll; }
    .grid .grid-content tr:nth-child(odd) {
      background-color: #f8f8f8; }
  .grid .grid-see-more {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2196f3;
    transition: 0.5s; }
  .grid .grid-see-more:hover {
    transform: scale(1.1);
    transition: 0.5s; }
  .grid .grid-see-more:active {
    transform: scale(0.8);
    transition: 0.5s;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .grid .download-button {
    color: #2196f3;
    background-color: #d5edff;
    padding: 8px;
    border-radius: 5px;
    position: absolute;
    transition: 0.5s;
    margin: 8px 0px; }
  .grid .download-button:hover {
    color: #d5edff;
    background-color: #2196f3; }
  .grid .download-button:active {
    transform: scale(0.8);
    transition: 0.5s; }
  .grid .search-grid {
    display: flex;
    padding: 8px 0px; }
    .grid .search-grid input {
      flex: 1 1;
      margin: 0;
      font-size: 14px;
      border: 1px solid #ccc;
      border-radius: 5px;
      margin-right: -4px; }
    .grid .search-grid .dropdownfilter {
      border: 1px solid #ccc;
      box-shadow: none;
      border-radius: 5px; }

.cardTab-container.reports {
  padding: 18px; }
  .cardTab-container.reports label {
    margin-bottom: 0px;
    padding: 0; }
  .cardTab-container.reports .search-report {
    display: flex;
    justify-content: space-between;
    padding: 16px 0px; }
  .cardTab-container.reports .normal-button {
    margin: 0;
    padding: 4px 16px;
    align-self: flex-end; }
  .cardTab-container.reports .options {
    display: flex;
    align-items: center; }

.download-button {
  color: #2196f3;
  background-color: #d5edff;
  padding: 8px;
  border-radius: 5px;
  transition: 0.5s;
  margin: 8px 0px; }

.page {
  color: #2196f3;
  padding: 10px;
  transition: 0.5s;
  -webkit-text-decoration: underline #d5edff;
          text-decoration: underline #d5edff;
  margin: 8px 0px; }

.download-button:hover {
  color: #d5edff;
  background-color: #2196f3; }

.download-button:active {
  transform: scale(0.8);
  transition: 0.5s; }

.container-date {
  display: flex;
  margin-bottom: 1px; }
  .container-date .column {
    display: table-column;
    padding: 4px; }
    .container-date .column .row {
      display: flex; }
  .container-date .dates-label {
    padding-bottom: 0.5em; }

.listItemContainer {
  display: flex;
  align-items: center; }
  .listItemContainer .border {
    border: 1px #ccc solid;
    padding: 12px 8px;
    border-radius: 3px; }
  .listItemContainer svg {
    width: 21px;
    margin-left: 4px;
    color: #8a8a8a;
    opacity: 0;
    padding: 8px; }
  .listItemContainer svg:active {
    transform: scale(0.9); }

.listItemContainer:hover svg {
  opacity: 1; }

.listItemContainer.loading {
  opacity: 0.5; }


.ranking-list li {
  display: flex;
  flex: 1 1;
  padding: 8px;
  position: relative;
  justify-content: center;
  align-items: center; }

.ranking-list h2 {
  padding-top: 4px; }

.ranking-list li:nth-child(odd) {
  border-bottom: solid 1px #f2f2f2;
  border-top: solid 1px #f2f2f2; }

.header {
	display: flex;
    justify-content: space-between;
	/* box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.3); */
	border-bottom: 2px solid #f2f2f2;
	background-color: #fff;
	height: 50px;
    min-height: 50px;
	width: 100%;
	z-index: 4;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
}

.title-header {
	display: flex;
	font-weight: bold;
	font-size: 16px;
	align-items: center;
}

.option-header {
	display: flex;
	cursor: pointer;
}

.span-header{
	display: flex;
	padding: 5px;
	border-left: 2px solid #f2f2f2;
}

.icon-header {
	width: 30px;
	padding-left: 10px;
	padding-right: 10px;
}
.userDropBox{
    width: 280px;
    background-color: #fff;
    position: absolute;
    right: 2px;
    top: 51px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
    border-radius: 3px;
    max-height: 0;
    overflow: hidden;
}

.info-userDropBox{
    display: flex;
    padding: 16px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.info-userDropBox img{
    height: 42px;
    width: 42px;
    background-color: #d5edff;
    border-radius: 100%;
    padding: 8px;
}

.info-userDropBox .data{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 16px
}

.info-userDropBox .data h1{
    font-weight: 700
}

.info-userDropBox .data h2{
    color: #828282
}

.userDropBox .footer{
    background-color: dodgerblue;
    height: 42px;
    padding:8px 16px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between
}

.button-personaldata{
	color:dodgerblue;
	background-color: white;
	text-decoration: none;
	font-weight: 700;
	font-size: 14px;
	border-radius: 32px;
	padding:12px 16px;
	border: 1px solid #fff;
}


.info-user {
	display: flex;
    align-items: center;
    border-left: 2px solid #f2f2f2;
    padding: 0px 15px 0px 4px;
	z-index: 9999;
}

.info-user.open .userDropBox{
	max-height: 1000px;
}


.info-user:hover .userDropBox{
	max-height: 1000px;
}

.name-user{
	font-weight: bold;
}

.photo-user {
	border-radius: 100%;
	overflow: hidden;
	width: 35px;
	height: 35px;
	margin-left: 10px;
}

.photo-user img {
	width: 100%;
	height: 100%;
}
.menu {
	transition: 0.5s;
	background-color: #FFF;
	overflow-x: hidden;
	/* box-shadow: 3px 10px 15px rgba(171, 174, 185, 0.4); */
	box-shadow: 3px 8px 20px rgba(0, 0, 0, 0.1);
	width: 55px;
    min-height: 100%;
    position: fixed;
    z-index: 99;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.menu.openMenu{
	width: 190px;
}

.menu:hover {
	width: 190px;
	transition: 0.5s;
    box-shadow: 10px 3px 20px rgba(0, 0, 0, 0.2);
}

.menu .option-menu {
	cursor: pointer;
	display: flex;
	font-size: 14px;
	font-weight: bold;
	color: #5a5a5a;
	align-items: center;
	padding: 5px;
	text-decoration: none;
}

.menu .option-menu:hover {
	border-left:#2196f3 4px solid;
}

.option-menu.menuActive svg, .menu .option-menu:hover svg, .option-menu.menuActive, .menu .option-menu:hover {
	stroke: #2196f3;
	color: #2196f3;
	transition: 100ms;
	background-color: #fafafa;
}


.id-menu {
	color: #fff;
    padding: 15px;
    margin-right: 10px;
    font-size: 16px;
}

.top {
	justify-content: space-between;
	background-color: #2196f3;
	height: 42px;
}

.top img {
	padding-right: 10px;
	height: 15px;
	width: 15px;
	transform: rotate(0deg);
	transition: 0.2s;
}

.icon-top.open{
	transform: rotate(45deg);
}

.option-menu.top:hover{
	background-color:  #2196f3;
}

.option-menu .icon-menu{
	padding-right: 5px;
}


.icon-menu img, .icon-menu svg {
	height: 25px;
	width: 25px;
	padding: 10px;
}

.icon-menu.coupon img {
	transform: rotate(-35deg)
}
.search-container {
  padding: 16px;
  flex: 1 1;
  display: flex;
  max-height: 100vh; }
  .search-container .filter-container {
    position: -webkit-sticky;
    top: 0; }
  .search-container main {
    flex: 1 1;
    position: relative;
    display: grid;
    align-content: flex-start; }
  @media (min-width: 980px) {
    .search-container main {
      grid-template-columns: 1fr 1fr; } }
  @media (min-width: 1290px) {
    .search-container main {
      grid-template-columns: 1fr 1fr 1fr; } }
  @media (min-width: 1390px) {
    .search-container main {
      grid-template-columns: 1fr 1fr 1fr 1fr; } }
  .search-container header {
    padding: 8px; }
    .search-container header nav {
      display: flex;
      align-items: center; }
      .search-container header nav input {
        background-color: #fff;
        border: none;
        box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.3);
        margin-right: 8px;
        margin-top: 0px; }
  .search-container .search-title {
    font-weight: 700;
    font-size: 21px; }
  .search-container .search-subtitle {
    color: #8a8a8a;
    margin-top: 4px;
    margin-bottom: 16px; }
  .search-container .search-footer {
    padding: 16px 0px; }

.smallCard {
	cursor: pointer;
	background-color: #FFF;
	box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.3);
	display: flex;
	align-items: center;
	border-radius: 3px;
	flex: 1 1;
	height: 125px;
	word-break: break-all;
	min-width: 200px;
	margin: 8px;
	padding: 8px;
	position: relative;
	transition: 0.3s;
}

.smallCard:hover{
	box-shadow: 0px 0px 15px #2196f360
}

.smallCard:active{
	transition: 0.3s;
	box-shadow: none
}

.content-smallCard {
	display: flex;
	height: 100%;
	width: 100%;
	padding-right: 16px;
	flex-direction: column;
	justify-content: space-between;
}

.header-smallCard {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
	margin-right: 10px;
	max-height: 90px;
	min-height: 90px;
	min-width: 90px;
	max-width: 90px;
	border-radius: 100%;
	overflow: hidden;
	
}

.img-profile {
	width: 100%;
	height: 100%;
	min-width: 92px;
	min-height: 92px;
	object-fit: cover;
	image-rendering: optimizeQuality;}

.img-profile.loading{
	display: none;
}

.name-logo{
	width: 100%;
	height: 100%;
	min-width: 112px;
	min-height: 112px;
	color: #2196f3;
	background-color: #d5edff;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: capitalize
}

.name-logo h1{
	font-size: 26px;
}

.title-smallCard {
	font-weight: bold;
	padding-top: 10px;
	text-transform: capitalize
}

.email-smallCard {
	color: #2196f3;
	padding-bottom: 5px;
	flex-wrap: wrap;
}

.footer-smallCard{
	color: #8d8d8d;
	padding: 8px 0;
}

.smallCard .status{
    height: 12px;
    width: 12px;
    border-radius: 12px;
    position: absolute;
    top: 12px;
    left: 12px
}

.smallCard .status.DEPRECATED, .smallCard .status.EXPIRED{
    background-color: #ff5f58;
    box-shadow: 0px 0px 15px #ff5f58
}

.smallCard .status.PUBLISHED, .smallCard .status.ENABLE{
	background-color: #2ac940;
    box-shadow: 0px 0px 15px #2ac940
}

.smallCard .status.PENDING{
	background-color: #ffba01;
    box-shadow: 0px 0px 15px #ffba01
}
.TinyCard {
	cursor: pointer;
	background-color: #FFF;
	box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.3);
	display: flex;
	align-items: center;
	border-radius: 3px;
	flex: 1 1;
	height: 35px;
	margin: 8px;
	padding: 8px;
	position: relative;
	transition: 0.3s;
}

.TinyCard:hover{
	box-shadow: 0px 0px 15px #2196f360
}

.TinyCard:active{
	transition: 0.3s;
	box-shadow: none
}

.content-TinyCard {
	display: flex;
	height: 100%;
	width: 100%;
	padding-left: 10px;
	flex-direction: column;
	justify-content: space-between;
}

.title-TinyCard {
	font-weight: bold;
	padding-top: 10px;
	text-transform: capitalize
}


.score {
	width: 90px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.score img {
	width: 14px;
	padding: 2px;
}
.card-profile {
	box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.3);
   background-color: #fff;
   padding: 8px;
   border-radius: 3px;
   margin: 4px;
   margin-top: 49px;
}

.card-profile-content{
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 8px
}

.card-img {
	align-self: center;
	margin-top: -57px;
	margin-bottom: 8px;
	position: relative;
}

.card-img img {
	width: 100%;
	height: 100%;
	object-fit: cover
}

.card-img svg{
	background-color: #2196f3;
	padding: 4px;
	border-radius: 50%;
	position: absolute;
	top: 60px;
	right: 4px;
}

.card-nameProfile{
	font-weight: bold;
	text-align: center;
	padding: 8px;
}

.status-item {
	font-size: 0.9em;
    color: #a5a5a5;
	text-transform: capitalize;
	padding: 8px
}

.card-subtitle {
	font-size: 0.9em;
    color: #a5a5a5;
	padding: 8px
}

.status-view{
	max-width: 12px;
	max-height: 12px;
	min-width: 12px;
	min-height: 12px;
	position: absolute;
	top: 0px;
	left: 0px;
	border-radius: 100%
}

.status-view.PUBLISHED{
	background-color: #2ac940;
    box-shadow: 0px 0px 5px #2ac940
}

.status-view.PENDING{
	background-color: #ffba01;
    box-shadow: 0px 0px 5px #ffba01
}

.status-view.defasada, .status-view.DEPRECATED, .status-view.EXPIRED{
	background-color: #ff5f58;
    box-shadow: 0px 0px 5px #ff5f58
}

.status-view.expirada{
	background-color: #CCCCCC;
}
.asideListCard {
	box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.3) ;
	background-color: #fff;
	padding: 16px;
	border-radius: 3px;
	margin: 4px;
	min-width: 150px;
	display: flex;
	align-items: center;
	word-break: break-all;
}

.scrollVertical{
	display: flex;
	flex-direction: column;
}

.asideListCard-item p:first-child, .asideListCard-table p:first-child {
	font-weight: 700;
}

.asideListCard-item div{
	padding: 4px 0px;
}

.asideListCard-item p:last-child {
	padding-top: 4px;
}

.asideListCard-table p:first-child {
	padding-right: 8px;
}

.asideListCard-table{
	display: flex;
	align-items: center;
	margin: 4px;
	padding: 4px;
	border-radius: 3px;
}

.asideListCard-table:nth-child(odd){
	background-color: #E2E2E2;
}

.asideListCard-title {
	font-weight: bold;
	padding-bottom: 8px
}

.asideListCard .link {
	color: #2196f3;
}
.AsideDiscountCard {
	box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.3);
	background-color: #fff;
	padding: 21px 16px;
	border-radius: 3px;
	margin: 4px;
    min-height: 48px;
}

.AsideDiscountCard-content{
	min-width: 165px;
    max-width: 250px;
    position: relative;
}

.AsideDiscountCard-title{
	font-weight: bold;
	text-align: center;
}

.AsideDiscountCard-footer {
	display: flex;
	align-items: center;
    justify-content: flex-end;
}

.AsideDiscountCard-value{
	font-weight: bold;
	font-size: 25px;
	text-align: center;
	padding-top: 8px;
}

.AsideDiscountCard-number {
	color: #585858;
}

.AsideDiscountCard-footer img{
	width: 21px;
    height: 21px;
	padding-right: 4px;
}
.asideUser {
	display: flex;
	flex-direction: column;
	padding-left: 15px;
	padding-right: 15px;
	min-width: 200px;
	width: 20%;
	height: 100%;
	max-width: 250px;
	overflow: auto;
}


.asideCard1, .asideCard2{
	margin: 3px;
	max-height: 200px;
}


@media (max-width: 650px) {
	.asideUser {
		width: 94%;
		height: 210px;
		flex-direction: row;
		overflow-y: hidden;
	}
}


.form-personalData {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 31px;
	flex-direction: column
}

.form-personalData p{
	padding-top: 20px;
	font-weight: 700;
	font-size: 14px
}

.form-personalData .title{
	font-weight: 700;
}

.personalData label.label-textarea {
	flex: 1 1;
	margin-left: 46px;
	margin-right: 46px
}

.btnSave{
	width: 180px;
	float: right;
	margin-right: 40px;
	margin-top: 18px
}

.form-firstRow{
	display: flex;
	flex: 1 1;
	flex-direction: row;
}

.form-firstRow input{
	width: 235px
}

.form-row{
	display: flex;
	flex: 1 1;
	flex-direction: row
}

.form-row select{
	width: 170px
}

.personalData-CPF{
	width: 165px
}

.personalData-surname{
	width: 180px
}
.title-historic {
	font-weight: bold;
	font-size: 21px;
	padding: 5px;
	padding-left: 20px;
	padding-bottom: 15px;
}

.historic-page{
	width: 500px;
}
#timeline-container {
  padding: 16px 0px; }
  #timeline-container .time-stamp-container {
    padding: 16px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    position: relative;
    padding-left: 32px;
    padding-top: 0px; }
    #timeline-container .time-stamp-container > .title {
      font-weight: 700;
      padding: 0px 0px 8px 8px; }
    #timeline-container .time-stamp-container .subtitle {
      color: #8a8a8a; }
    #timeline-container .time-stamp-container .content {
      padding-top: 8px; }
    #timeline-container .time-stamp-container > .title::before {
      content: "";
      width: 16px;
      height: 16px;
      transition: 250ms;
      transition-delay: 500ms;
      background-color: #f2f2f2;
      position: absolute;
      left: 10px;
      top: 0px;
      border-radius: 50%; }
    #timeline-container .time-stamp-container .time-fact-container {
      padding: 4px;
      display: flex;
      flex-direction: column; }
      #timeline-container .time-stamp-container .time-fact-container .container {
        border: solid 3px #f2f2f2;
        padding: 16px;
        border-radius: 10px;
        border-top-left-radius: 0px;
        border-bottom-right-radius: 30px;
        margin-bottom: 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center; }
        #timeline-container .time-stamp-container .time-fact-container .container .title {
          font-weight: 700; }
        #timeline-container .time-stamp-container .time-fact-container .container .value {
          font-size: 21px; }
      #timeline-container .time-stamp-container .time-fact-container .details {
        align-self: flex-end;
        cursor: pointer;
        padding-top: 8px;
        color: #2193f3; }
      #timeline-container .time-stamp-container .time-fact-container .details:hover {
        text-decoration: underline; }
  #timeline-container .time-stamp-container::before {
    content: '';
    position: absolute;
    background-color: #f2f2f2;
    width: 3px;
    top: 0;
    bottom: 0;
    left: 16px;
    flex: 1 1;
    border-radius: 6px; }
  #timeline-container .time-stamp-container::after {
    content: '';
    position: absolute;
    background-color: #f2f2f2;
    width: 3px;
    top: 12px;
    bottom: 0;
    left: 16px;
    flex: 1 1;
    height: 0px;
    border-radius: 6px;
    transition: 500ms;
    background-color: #2193f3; }
  #timeline-container .time-stamp-container:hover::after {
    height: 100%;
    transition-delay: 100ms; }
  #timeline-container .time-stamp-container::before {
    content: '';
    position: absolute;
    background-color: #f2f2f2;
    width: 3px;
    top: 0;
    bottom: 0;
    left: 16px;
    flex: 1 1;
    border-radius: 6px;
    transition: 500ms; }
  #timeline-container .time-stamp-container:hover > .title::before {
    background-color: #2193f3;
    transition-delay: 0ms;
    transition: 500ms; }

.contacts{
	display: flex;
	flex: 1 1;
	flex-direction: column;
	height: 100%;
}

.form-contactsData {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 16px 32px;
	border-bottom: solid 1px #f1f1f1;
	align-items: center
}

.contactsContents{
	display: flex;
	flex-wrap: wrap;
	position: relative;
	flex: 1 1;
	align-content: flex-start
}

.contactsLabel{
	width: 42% !important
}

.contactsData .contactsLabel.label-textarea {
	flex: 1 1;
	margin-left: 46px;
	margin-right: 46px
}

.btnSave{
	width: 180px;
	float: right;
	margin-right: 40px;
	margin-top: 18px
}
.personalDataAddress{
	padding: 0px 16px
}

.form-personalDataAddress {
	display: flex;
	flex-wrap: wrap;
	border-bottom: solid 1px #f1f1f1;
	padding-bottom: 4px;
	padding-top: 20px;
	padding-left: 32px
}

.personalDataAddress-content{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	flex-direction: column
}

.CEP{
    position: relative
}
.CEP input{
    margin-right: 32px;
	width: 110px
}
.CEP img{
    position: absolute;
    right: 10px;
    top: 26px
}

.form-number{
	width: 115px
}

.form-name{
	width: 355px
}

.form-district{
	width: 295px
}

.btnSave-address{
	width: 180px;
	float: right;
	margin-right: 40px;
	margin-top: 18px
}

.refresh-icon {
	width: 20px;
	display: inline-flex;
	cursor: pointer;
}

.address-content{
	display: flex;
	flex-wrap: wrap;
	padding-top: 4px
}

#address-list li:last-child{
	-webkit-animation: bounce 0.5s;
	        animation: bounce 0.5s
}

.personalDataAddress-row{
	display: flex;
	flex: 1 1
}

@-webkit-keyframes bounce{
	0% { transform: scale(0) }
	100% { transform: scale(1) }
}

@keyframes bounce{
	0% { transform: scale(0) }
	100% { transform: scale(1) }
}
.TabContainer {
	box-shadow: 3px -3px 25px rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    flex: 1 1;
    box-sizing: border-box;
    max-height: 100%;
    background-color: #FFF
}

.TabContainer .header-cardTabc{
	display: flex;
	align-items: center;
	padding: 16px;
}

.option-cardTabc a{
	text-decoration: none;
	color: #636363;
	padding: 8px;
	cursor: pointer;
}

.option-cardTabc a.active-user{
	font-weight: bold;
	color: black;
    border-bottom: 3px solid #2196f3;
}

.routec{
    box-sizing: border-box;
	position: relative;
    flex: 1 1;
}

/* 
@media (max-width: 790px) {
	.cardTab {
    	width: 99%;
    	padding-bottom: 20px;
	}
} */



.border-list li {
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 3px;
  margin: 4px 0px; }
  .border-list li .title {
    font-weight: 700; }
  .border-list li h2 {
    color: #8a8a8a; }

.section {
	display: flex;
	height: 100%;
	flex: 1 1
}


@media (max-width: 650px) {
	.section {
    	flex-direction: column;
	}
}
.card {
   box-shadow:  2px 2px 11px rgba(171, 174, 185, 0.3);
   background-color: #fff;
   position: relative;
   padding: 16px;
   border-radius: 3px;
   margin: 4px;
}

.card .title{
    font-weight: 700;
    padding-bottom: 4px
}

.card .text{
    padding-bottom: 8px
}

.aside-button-card{
    display: flex;
    flex-direction: column;
}

.aside-button-card > a{
    color: #2196f3;
    align-self: center;
    padding: 16px 0px 8px 0px;
    transition: 0.5s;
}

.aside-button-card > a:hover{
    color: #2196f3;
}

.aside-button-card > a:active{
    transform: scale(0.8);
    transition: 0.5s
}
.asideProduct {
	display: flex;
	flex-direction: column;
	padding: 10px;
	min-width: 200px;
	width: 250px;
	overflow: auto;
}


.asideCard1, .asideCard2{
	margin: 3px;
	max-height: 200px;
}


@media (max-width: 650px) {
	.asideProduct {
		height: 180px;
		display: flex;
		flex-direction: row;
		width: 100%
	}
}


.reach-visualization .reach-ellipse .data {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center; }

.reach-visualization {
  display: flex;
  padding: 16px;
  padding-bottom: 28px; }
  .reach-visualization .reach-ellipse {
    flex: 1 1;
    border-radius: 50%;
    border: 1px solid #c8c8c8;
    display: flex;
    padding: 31px;
    max-width: 100%;
    position: relative; }
    .reach-visualization .reach-ellipse .data {
      flex: 1 1;
      color: #2196FD;
      min-width: 90px;
      min-height: 90px; }
      .reach-visualization .reach-ellipse .data h1 {
        font-weight: 700;
        font-size: 22px;
        padding: 4px;
        transition: 0.5s; }
    .reach-visualization .reach-ellipse .data.small h1 {
      font-size: 18px;
      transition: 0.5s; }
    .reach-visualization .reach-ellipse .reach-person {
      background-color: #c8c8c8;
      height: 25px;
      width: 25px;
      border-radius: 50%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center; }
      .reach-visualization .reach-ellipse .reach-person svg {
        position: absolute;
        transform: translateY(-1.5px); }

.abstractModal-container {
  flex: 1 1; }
  .abstractModal-container .value {
    font-weight: 700; }
  .abstractModal-container header {
    display: flex;
    align-items: center;
    margin-bottom: 32px; }
    .abstractModal-container header .info {
      margin-left: 16px; }
      .abstractModal-container header .info h2 {
        color: #2196f3;
        padding: 4px 0px; }
      .abstractModal-container header .info h3 {
        color: #8a8a8a; }
  .abstractModal-container main h2 {
    margin-bottom: 16px; }
  .abstractModal-container main ul {
    display: flex;
    overflow: scroll; }
  .abstractModal-container main .selectableItem {
    margin: 0; }

/* @media (max-width: 650px) {
	.product {
    	flex-direction: column;
	}
}  */
.form-StoreData{
	display: flex;
	flex-wrap: wrap;
	padding-top: 20px;
	padding-left: 32px
}

.StorelData label.label-textarea {
	flex: 1 1;
	margin-left: 46px;
	margin-right: 46px
}

.btnSave{
	width: 180px;
	float: right;
	margin-right: 40px;
	margin-top: 18px
}

.form-StoreData input {
	width: 150px
}

.form-StoreDataDescription{
	display: flex;
	flex-wrap: wrap;
	padding-left: 32px
}

.form-StoreDataDescription input {
	width: 500px
}
.form-storeData {
	display: flex;
	padding-top: 20px;
	flex: 1 1;
	flex-direction: column;
	padding-left: 32px
}

.form-storeData > label{
	margin: 0
}

.storeData-row{
	display: flex;
	flex: 1 1
}

.btnSave{
	width: 180px;
	float: right;
	margin-right: 40px;
	margin-top: 18px
}

.CEP{
    position: relative
}
.CEP input{
    margin-right: 32px;
	width: 110px
}
.CEP img{
    position: absolute;
    right: 10px;
    top: 26px
}

.form-number{
	width: 115px
}

.form-name{
	width: 355px
}

.form-district{
	width: 295px
}
.list{
    flex: 1 1;
    min-width: 150px;
}

.list .title{
    padding: 16px;
    padding-left: 32px
}

.list  li{
    display: flex;
    align-items: center;
    height: 21px;
    padding: 16px;
    position: relative;
}

.list li:nth-child(odd){
    background-color: #eaeaea;
}

.list li > h1{
   padding-left: 16px
}

.bold{
    font-weight: 700
 }

 .list .actionsl div > svg:hover > path{
    fill: #2196f3;
    transform: scale(1.1);
    transform-origin: center;
    transition: 0.5s
}

.list .actionsl div > svg > path{
    transform-origin: center;
    transition: 0.5s;
}

.list .actionsl div > svg{
    position: absolute;
    right: 16px;
    top: 14px
}

.list .actionsl div > svg:nth-child(2){
    position: absolute;
    right: 46px;
    top: 14px
}
.contact-form {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding-top: 20px;
}

.btnSave{
	width: 180px;
	float: right;
	margin-right: 40px;
	margin-top: 18px
}

.actions-contacts{
	width: 180px;
	display: flex;
	float: right;
	margin-top: 32px;
	margin-right: 32px
}

.actions-contacts > div{
	width: 180px
}

/* 
@media (max-width: 800px) {
	.personalData label {
    	width: 92%;
	}
} */
.TabContainer {
	box-shadow: 3px -3px 25px rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    flex: 1 1;
    box-sizing: border-box;
    max-height: 100%;
    background-color: #FFF
}

.TabContainer .header-cardTabc{
	display: flex;
	align-items: center;
	padding: 16px;
}

.option-cardTabc a{
	text-decoration: none;
	color: #636363;
	padding: 8px;
	cursor: pointer;
}

.option-cardTabc a.active-user{
	font-weight: bold;
	color: black;
    border-bottom: 3px solid #2196f3;
}

.routec{
    box-sizing: border-box;

    flex: 1 1;

}

/* 
@media (max-width: 790px) {
	.cardTab {
    	width: 99%;
    	padding-bottom: 20px;
	}
} */



.asideUser {
	display: flex;
	flex-direction: column;
	padding-left: 15px;
	padding-right: 15px;
	min-width: 200px;
	width: 20%;
	height: 100%;
	overflow: auto;
}

.asideCard1, .asideCard2{
	margin: 3px;
	max-height: 200px;
}

@media (max-width: 650px) {
	.asideUser {
		width: 94%;
		height: 210px;
		flex-direction: row;
		overflow-y: hidden;
	}
}


/* .product {
	display: flex;
	height: 100%;
	flex: 1;
} */
/* 

@media (max-width: 650px) {
	.product {
    	flex-direction: column;
	}
	.cardTabProduct{
		width: 100%;
	}
} */
.promotion{
	display: flex;
	flex-direction: column;
	flex: 1 1;
	height: 100%;
	padding: 0px 16px;
}

.header-promotion{
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	padding: 16px 8px 0 8px;
	min-height: 62px;
	margin-bottom: 10px;
}

.none{
	display: none
}


.footer-promotion{
	min-height: 62px;
	display: flex;
	justify-content: space-between;
	padding: 32px 0;
}

.paginator{
	min-width: 160px;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	position: relative;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none
}

 .number-pagination {
	 padding: 0;
	 padding-bottom: 4px;
	 height: 42px;
	 width: 160px;
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 text-align: center;
	 color: #2196f3;
 }

 .number-pagination:hover {
	color: #67bbff;
}

.number-pagination:active {
	font-size: 12px;
	
}

.arrow-right {
	position: absolute;;
	right: 0;
	height: 42px;
}

.arrow-left {
	position: absolute;;
	left: 0;
	height: 42px;
}

.option-footer{
	position: relative;
}
.store{
	display: flex;
	flex-direction: column;
	flex: 1 1;
	height: 100%;
	padding: 0px 16px;
}

.header-store{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 16px 0 0 0;
	min-height: 62px;
	z-index: 1;
	margin-bottom: 10px;
	box-shadow: 0px 16px 20px #f5f6fa
}

.container-store{
	display: grid;
	flex-wrap: wrap;
	flex: 1 1;
	align-content: flex-start;
	overflow-y: scroll;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

@media (min-width: 733px)  {
	.container-store{
		grid-template-columns: 1fr 1fr
	}
}

@media (min-width: 1054px)  {
	.container-store{
		grid-template-columns: 1fr 1fr 1fr
	}
}

@media (min-width: 1370px)  {
	.container-store{
		grid-template-columns: 1fr 1fr 1fr 1fr
	}
}

.footer-store{
	min-height: 62px;
	/* display: flex; */
}

.paginator{
	min-width: 160px;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	position: relative;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none
}

 .number-pagination {
	 padding: 0;
	 padding-bottom: 4px;
	 min-width: 160px;
 }

 .arrow-right {
	 position: absolute;;
	 right: 0;
	 height: 42px;
 }

 .arrow-left {
	position: absolute;;
	left: 0;
	height: 42px;
}
.operatorSearch {
    display: flex;
    padding-right: 8px;
    align-items: center
}

.main-router{
	display: flex;
    min-height: 100vh;
    max-height: 100vh;
}

.main {
	display: flex;
	min-height: 100%;
}

.title-page{
    font-weight: 700;
    font-size: 21px
}

.page-title-header{
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 16px ;
    max-height: 52px;
}

.container {
	display: flex;
	flex-direction: column;
    flex: 1 1;
    max-width: calc(100vw/1.5);
}

.container-router{
    flex: 1 1;
    padding-left: 56px;
    display: flex;
    position: relative;
    flex-direction: column;
}

.hidden {
    display: none;
}

.input-shadow {
	min-height: 22px;
	font-size: 1.1em;
	box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.5);
	margin: 0px;
	border: none
}



.container-search{
	display: grid;
	flex-wrap: wrap;
	flex: 1 1;
	align-content: flex-start;
	overflow-y: scroll;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}


@media (min-width: 733px)  {
	.container-search{
		grid-template-columns: 1fr 1fr
	}
}

@media (min-width: 1054px)  {
	.container-search{
		grid-template-columns: 1fr 1fr 1fr
	}
}

@media (min-width: 1370px)  {
	.container-search{
		grid-template-columns: 1fr 1fr 1fr 1fr
	}
}

.operatorData-page{
	flex: 1 1;
	display: flex
}

.operator-data{
	padding: 0px 16px;
	flex: 1 1;
	display: flex
}

.operatorData-form{
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 4px;
	padding-top: 20px;
	padding-left: 32px;
	flex: 1 1
}

.operatorData-content{
	display: flex;
	flex: 1 1;
	justify-content: space-between;
	flex-wrap: wrap;
	flex-direction: column;
}

.operatorData-row{
	display: flex;
	flex: 1 1
}

.operatorData-row input{
	width: 250px
}
.selectable-list {
  flex: 1 1;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start; }
  .selectable-list .loadingGif {
    margin-right: 8px; }

.selectable-list h1 {
  padding: 4px 0px;
  padding-bottom: 8px;
  font-weight: 700; }

.selectable-list li {
  padding: 4px;
  background-color: #f2f2f2;
  margin: 4px 0px; }

.selectableList-container {
  margin: 4px;
  flex: 1 1; }
  .selectableList-container .list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    margin: 8px 0;
    min-width: 230px; }

.addPermission-button {
  max-height: 30px;
  max-width: 30px;
  padding: 4px;
  margin: 0 16px;
  position: relative;
  z-index: 1; }

.addPermission-button.asideList-button {
  right: 8px;
  margin: 0 !important;
  height: 16px !important;
  width: 16px !important;
  padding: 8px; }

.addPermission-button:hover, .addPermission-button:active {
  -webkit-animation: bounce 0.5s;
          animation: bounce 0.5s; }

.addPermission-button:active {
  transform: scale(0.8);
  -webkit-animation: bounce 0.5s;
          animation: bounce 0.5s; }

.selectable-title {
  flex: 1 1;
  padding: 8px;
  box-sizing: border-box;
  margin-right: 8px; }
  .selectable-title h1 {
    font-weight: 700; }

.selectable-title h1 {
  padding-bottom: 4px; }

.selectable-title h2 {
  padding: 0px 4px; }

.selectable-list-container {
  overflow: scroll;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px 16px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.emptyListItem {
  color: #2196f3;
  border: dashed 1px #2196f3;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: #d5edff !important;
  border-radius: 5px; }
  .emptyListItem svg, .emptyListItem path, .emptyListItem circle {
    fill: #2196f3;
    margin: 16px; }

.cardTabOperator{
	box-shadow: 3px -3px 25px rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    flex: 1 1;
    box-sizing: border-box;
    background-color: #FFF;
}

.cardTabOperator .header-cardTabOperator{
	display: flex;
	align-items: center;
	padding: 16px;
}
.option-cardTabOperator a{
	text-decoration: none;
	color: #636363;
	padding: 8px;
	cursor: pointer;
}

.option-cardTabOperator a.activeTab{
	font-weight: bold;
	color: black;
    border-bottom: 3px solid #2196f3;
}

.header-cardTabOperator .option-cardTabOperator.active{
	font-weight: bold;
	color: black;
	border-bottom: 3px solid #2196f3;
}

.route-operator{
    box-sizing: border-box;
	display: flex;
	flex: 1 1;
}
.asideOperator {
	display: flex;
	flex-direction: column;
	padding: 10px;
	min-width: 200px;
	width: 250px;
	overflow: auto;
}
.operator{
	display: flex;
	flex: 1 1;
    width: 100%;
    height: 100%;
}
.cardProfile{
	display: flex;
	flex: 1 1;
	width: 100%;
	height: 100%
}
.profile-data{
	flex: 1 1;
	display: flex;
	padding: 16px
}

.profileData-form{
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 4px;
	padding-top: 32px;
	flex: 1 1
}

.profileData-content{
	display: flex;
	flex: 1 1;
	flex-direction: column;
	position: relative;
}

.profileData-row{
	display: flex;
	flex: 1 1
}

.profileData-row input{
	width: 250px
}

.profileData-name{
	width: 255px;
}
.cardTabProfile {
	box-shadow: 3px -3px 25px rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    flex: 1 1;
    box-sizing: border-box;
    background-color: #FFF;
}

.cardTabProfile .header-cardTabProfile{
	display: flex;
	align-items: center;
	padding: 16px;
}
.option-cardTabProfile a{
	text-decoration: none;
	color: #636363;
	padding: 8px;
	cursor: pointer;
}

.option-cardTabProfile a.activeTab{
	font-weight: bold;
	color: black;
    border-bottom: 3px solid #2196f3;
}

.header-cardTabProfile .option-cardTabProfile.active{
	font-weight: bold;
	color: black;
	border-bottom: 3px solid #2196f3;
}

.route-profile{
    box-sizing: border-box;
	display: flex;
	flex: 1 1;
}
.asideProfile {
	display: flex;
	flex-direction: column;
	padding: 10px;
	min-width: 200px;
	width: 250px;
	overflow: auto;
}


@media (max-width: 650px) {
	.asideProfile {
		height: 180px;
		display: flex;
		flex-direction: row;
		width: 100%
	}
}


.notification-box {
    position: absolute;
    bottom: 1em;
    right: 1em;
    background-color: #fff;
    color: #8d8d8d;
    box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.3);
    padding: 0.5em;
}
.dragged > .selectableItem {
  opacity: 0.1;
  border-top: 1px solid #999999;
  border-bottom: 1px solid #ffffff; }

.floating > .selectableItem {
  background-color: #ffffff !important;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.2) !important; }

.dl-list {
  display: flex !important;
  flex-wrap: wrap; }

.app-manager-card {
  width: calc(100vw - 700px);
  margin-right: 120px; }

.page-container {
  padding: 32px 16px;
  flex: 1 1;
  display: flex;
  flex-direction: column; }
  .page-container .title-page {
    font-size: 21px;
    padding-bottom: 32px;
    font-weight: bold; }
  .page-container .flex-row {
    display: flex;
    flex-direction: row; }
  .page-container .space-between {
    justify-content: space-between; }
  .page-container .flex {
    flex: 1 1;
    display: flex; }
  .page-container .center {
    justify-content: center; }
  .page-container .column {
    flex-direction: column; }

.promoapp-home {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .promoapp-home header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px; }
    .promoapp-home header h1 {
      font-weight: 700;
      font-size: 16px; }
    .promoapp-home header svg {
      height: 21px; }
  .promoapp-home #app-home-section {
    -webkit-animation: easeInAnimationY 500ms linear;
            animation: easeInAnimationY 500ms linear; }
  .promoapp-home footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-top: 1px solid #eaeaea; }
    .promoapp-home footer div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
    .promoapp-home footer h1 {
      font-size: 10px;
      color: #8a8a8a;
      padding-top: 4px; }
    .promoapp-home footer svg {
      stroke: #8a8a8a; }
    .promoapp-home footer .active > svg, .promoapp-home footer .active > h1 {
      color: #000;
      stroke: #000; }
  .promoapp-home .banner {
    height: 190px;
    width: 100%;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-color: #f2f2f2; }
    .promoapp-home .banner img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      transition: 500ms; }
  .promoapp-home main {
    overflow: scroll;
    flex: 1 1;
    padding-bottom: 16px; }
  .promoapp-home h2 {
    font-weight: 700;
    padding: 16px; }
  .promoapp-home #highlighted-coupon-container {
    border: none;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
    margin-top: 8px; }
    .promoapp-home #highlighted-coupon-container h2 {
      padding: 4px 0; }
    .promoapp-home #highlighted-coupon-container img {
      height: 180px;
      width: 180px; }
  .promoapp-home section {
    display: flex;
    padding: 0px 16px;
    overflow-x: scroll;
    flex: 1 1;
    box-sizing: border-box; }
    .promoapp-home section .square {
      height: 100px;
      min-width: 100px;
      background-color: #f2f2f2;
      border-radius: 5px;
      margin-right: 5px; }
    .promoapp-home section .rect {
      height: 160px;
      min-width: 100%;
      background-color: #f2f2f2;
      border-radius: 5px;
      margin-right: 8px; }

#smartphone-frame {
  height: 80vh;
  background-color: #fff;
  border: 8px solid #212121;
  box-sizing: border-box;
  min-width: 340px;
  border-radius: 42px;
  min-height: 700px;
  max-height: 700px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  max-width: 350px;
  padding: 18px 0px;
  padding-bottom: 8px;
  position: -webkit-sticky;
  position: sticky;
  top: 137px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  transition: 1000ms;
  box-shadow: 3px 3px 50px rgba(0, 0, 0, 0.2); }
  #smartphone-frame .content {
    width: 100%;
    overflow: scroll; }

#smartphone-frame::before {
  height: 32px;
  border-radius: 16px;
  width: 60%;
  background-color: #212121;
  content: "";
  position: absolute;
  top: -16px;
  z-index: 999; }

.notification-manager {
  position: absolute;
  background-color: #282828;
  color: #fff;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.3);
  padding: 16px 8px;
  border-radius: 10px;
  width: 90%;
  display: flex;
  transition: 300ms;
  max-height: 100px;
  top: -100%;
  opacity: 0;
  min-height: 30px;
  word-break: break-all; }
  .notification-manager .icon {
    height: 32px;
    min-width: 32px;
    background-color: #000;
    border-radius: 5px;
    margin-right: 8px; }
  .notification-manager h1 {
    font-weight: 700;
    min-height: 20px; }

.notification-manager.show {
  -webkit-animation: notify 500ms;
          animation: notify 500ms;
  opacity: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

.notification-manager.hide {
  -webkit-animation: hideNotify 500ms;
          animation: hideNotify 500ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

@-webkit-keyframes notify {
  0% {
    top: -100%; }
  50% {
    top: 40px; }
  75% {
    top: 18px; }
  100% {
    top: 22px; } }

@keyframes notify {
  0% {
    top: -100%; }
  50% {
    top: 40px; }
  75% {
    top: 18px; }
  100% {
    top: 22px; } }

@-webkit-keyframes hideNotify {
  0% {
    right: 0px;
    top: 22px; }
  100% {
    right: -100%;
    top: 22px; } }

@keyframes hideNotify {
  0% {
    right: 0px;
    top: 22px; }
  100% {
    right: -100%;
    top: 22px; } }

#app-home-section {
  margin-right: 8px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 5px; }
  #app-home-section img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 5px;
    object-fit: cover; }
  #app-home-section h1 {
    padding-top: 4px;
    padding-left: 4px; }

#app-home-section-container.loading-section {
  opacity: 0.2;
  transition: 500ms; }
  #app-home-section-container.loading-section .actions {
    display: none; }

#app-home-section-container {
  position: relative; }
  #app-home-section-container .status {
    top: -3px;
    right: 6px;
    border-radius: 7.5;
    position: absolute; }
  #app-home-section-container .infos {
    display: flex;
    justify-content: flex-end;
    padding: 8px 0px;
    cursor: pointer; }
    #app-home-section-container .infos svg {
      margin-left: 4px; }
    #app-home-section-container .infos svg:hover {
      transform: scale(1.1);
      transform-origin: center;
      transition: 0.5s; }
    #app-home-section-container .infos svg:active {
      transform: scale(0.8);
      transform-origin: center; }
  #app-home-section-container a {
    text-align: center;
    padding: 8px 4px; }
  #app-home-section-container a:visited, #app-home-section-container a:link {
    color: #2196f3; }
  #app-home-section-container a:hover {
    color: #0b68b4; }

#highlighted-coupon-container {
  height: 130px;
  width: 200px;
  background-color: #fff;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  padding: 16px;
  box-sizing: border-box;
  border: solid 1px #ccc; }
  #highlighted-coupon-container img {
    position: absolute;
    height: 130px;
    width: 130px;
    right: -45px;
    bottom: -20px;
    transform: rotate(-25deg); }
  #highlighted-coupon-container h1 {
    width: 110px;
    font-weight: 700;
    font-size: 12px; }
  #highlighted-coupon-container h2 {
    color: #8a8a8a;
    padding-top: 4px; }

.actions svg:hover {
  transform: scale(1.1);
  transform-origin: center;
  transition: 0.5s; }

.actions svg:active {
  transform: scale(0.8);
  transform-origin: center;
  transition: 0.5s; }

#emoji-picker {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 9; }
  #emoji-picker svg {
    margin-bottom: 8px; }
  #emoji-picker .emoji-container {
    max-height: 0px;
    overflow: hidden;
    transition: 300ms; }
  #emoji-picker .emoji-container.open {
    max-height: 320px;
    transition: 300ms; }

.root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  flex: 1 1;
  background-color: #f5f6fa; }

a {
  color: inherit;
  text-decoration: none; }

body {
  background-color: #f5f6fa; }

.popup-content {
  border-radius: 5px;
  width: 70%;
  border: none !important; }

.popup-overlay {
  justify-content: center; }

.client {
  width: 100%;
  height: 95%; }

.smooth-scroll {
  scroll-behavior: smooth; }

::-webkit-scrollbar {
  display: none; }

.input-error::-webkit-input-placeholder {
  color: #ff5f58; }

.input-error::-ms-input-placeholder {
  color: #ff5f58; }

.input-error::placeholder {
  color: #ff5f58; }

.input-error {
  color: #ff5f58;
  border: solid 1px #ff5f58;
  -webkit-animation: shake 0.3s;
          animation: shake 0.3s; }

.shake {
  -webkit-animation: shake 0.3s;
          animation: shake 0.3s; }

.react-confirm-alert-body > h1 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 16px; }

.react-confirm-alert-button-group {
  justify-content: flex-end; }

.react-confirm-alert-button-group button:first-child {
  color: #2196f3;
  background-color: #fff; }

.react-confirm-alert-button-group button:last-child {
  background-color: #2196f3;
  color: #fff; }

.DateInput_input__small {
  width: 83px; }

.TabContainer label, .tabScreen-container label {
  margin-bottom: 8px;
  margin-right: 8px; }

.SingleDatePickerInput__withBorder {
  border-color: #ccc !important;
  border-radius: 3px !important; }

.SingleDatePicker {
  width: 100px; }

.container {
  max-width: 100vw; }

@media (max-width: 1300px) {
  .container, .client, .main {
    height: auto; } }

@media (max-width: 650px) {
  .root {
    height: auto; } }

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

@-webkit-keyframes bounce {
  0% {
    transform: scale(1); }
  25% {
    transform: scale(0.8); }
  75% {
    transform: scale(1.19); }
  100% {
    transform: scale(1); } }

@keyframes bounce {
  0% {
    transform: scale(1); }
  25% {
    transform: scale(0.8); }
  75% {
    transform: scale(1.19); }
  100% {
    transform: scale(1); } }

@-webkit-keyframes click {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(0.4); }
  100% {
    transform: scale(0.9); } }

@keyframes click {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(0.4); }
  100% {
    transform: scale(0.9); } }

@-webkit-keyframes opacityAnimation {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes opacityAnimation {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes shake {
  0% {
    transform: translateX(8px); }
  25% {
    transform: translateX(-8px); }
  50% {
    transform: translateX(8px); }
  75% {
    transform: translateX(-8px); }
  100% {
    transform: translateX(0px); } }

@keyframes shake {
  0% {
    transform: translateX(8px); }
  25% {
    transform: translateX(-8px); }
  50% {
    transform: translateX(8px); }
  75% {
    transform: translateX(-8px); }
  100% {
    transform: translateX(0px); } }

.placeholder-shiny {
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
          animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #ddd 8%, #f9f9f9 20%, #ddd 33%);
  background-size: 800px 104px;
  position: relative; }

.status {
  height: 12px;
  width: 12px;
  border-radius: 100%; }

.status.yellow {
  background-color: #ffba01;
  box-shadow: 0px 0px 5px #ffba01; }

.status.red {
  background-color: #ff5f58;
  box-shadow: 0px 0px 5px #ff5f58; }

.status.green {
  background-color: #2ac940;
  box-shadow: 0px 0px 5px #2ac940; }

.icon-bounce {
  padding: 8px;
  height: 21px;
  transition: 0.5s;
  border-radius: 100%; }

.icon-bounce:active {
  transform: scale(0.7);
  transition: 0.3s; }

.icon-bounce:hover {
  background-color: #f0f0f0; }

.react-confirm-alert-overlay {
  z-index: 9999; }

@-webkit-keyframes easeInAnimationY {
  from {
    transform: translateY(10px);
    opacity: 0; }
  to {
    transform: translateY(0px);
    opacity: 1; } }
@keyframes easeInAnimationY {
  from {
    transform: translateY(10px);
    opacity: 0; }
  to {
    transform: translateY(0px);
    opacity: 1; } }

