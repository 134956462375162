.cardTabProduct {
	box-shadow: 3px -3px 25px rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    flex: 1;
    box-sizing: border-box;
    background-color: #FFF;
}

.cardTabProduct .header-cardTabProduct{
	display: flex;
	align-items: center;
	padding: 16px;
}
.option-cardTabProduct a{
	text-decoration: none;
	color: #636363;
	padding: 8px;
	cursor: pointer;
}

.option-cardTabProduct a.activeTab{
	font-weight: bold;
	color: black;
    border-bottom: 3px solid #2196f3;
}

.header-cardTabProduct .option-cardTabProduct.active{
	font-weight: bold;
	color: black;
	border-bottom: 3px solid #2196f3;
}

/* .route-promotion{
    box-sizing: border-box;
	display: flex;
	flex: 1;
} */

.cardTab-container{
	flex: 1;
	padding: 8px
}

.tab-content{
	padding: 16px 26px;
}

.tab-content .title{
	font-weight: 700;
}

/*

@media (max-width: 790px) {
	.cardTabProduct {
    	width: 99%;
    	padding-bottom: 20px;
	}
}
*/


