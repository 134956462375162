#highlighted-coupon-container{
    height: 130px;
    width: 200px;
    background-color: #fff;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    padding: 16px;
    box-sizing: border-box;
    border: solid 1px #ccc;

    img{
        position: absolute;
        height: 130px;
        width: 130px;
        right: -45px;
        bottom: -20px;
        transform: rotate(-25deg);
    }

    h1{
        width: 110px;
        font-weight: 700;
        font-size: 12px;
    }

    h2{
        color: #8a8a8a;
        padding-top: 4px;
    }
}