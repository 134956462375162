.contact-form {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding-top: 20px;
}

.btnSave{
	width: 180px;
	float: right;
	margin-right: 40px;
	margin-top: 18px
}

.actions-contacts{
	width: 180px;
	display: flex;
	float: right;
	margin-top: 32px;
	margin-right: 32px
}

.actions-contacts > div{
	width: 180px
}

/* 
@media (max-width: 800px) {
	.personalData label {
    	width: 92%;
	}
} */