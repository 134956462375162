.profile-data{
	flex: 1;
	display: flex;
	padding: 16px
}

.profileData-form{
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 4px;
	padding-top: 32px;
	flex: 1
}

.profileData-content{
	display: flex;
	flex: 1;
	flex-direction: column;
	position: relative;
}

.profileData-row{
	display: flex;
	flex: 1
}

.profileData-row input{
	width: 250px
}

.profileData-name{
	width: 255px;
}