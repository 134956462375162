$color: #2196FD;
$gray: #c8c8c8;
$size: 300px;

%center{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.reach-visualization{
    // max-height: $size;
    // max-width: $size;
    // min-height: $size;
    // min-width: $size;
    display: flex;
    padding: 16px;
    padding-bottom: 28px;

    .reach-ellipse{
        flex: 1;
        border-radius: 50%;
        border: 1px solid $gray;
        display: flex;
        padding: 31px;
        max-width: 100%;
        position: relative;

        .data{
            flex: 1;
            @extend %center;
            color: $color;
            min-width: 90px;
            min-height: 90px;

            h1{
                font-weight: 700;
                font-size: 22px;
                padding: 4px;
                transition: 0.5s
            }
        }

        .data.small h1{
            font-size:18px;
            transition: 0.5s
        }

        .reach-person{
            background-color: $gray;
            height: 25px;
            width: 25px;
            border-radius: 50%;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;

            svg{
                position: absolute;
                transform: translateY(-1.5px);
            }
        }
    }
}