.kpi-info, .ranking-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition:  500ms;
    text-align: center;

    .value-container{
        display: flex;

        align-items: center;

        .label{
            height: 8px;
            width: 8px;
            border-radius: 8px;
            margin-right: 8px;
        }
    }

    .value{
        font-weight: 700;
        font-size: 26px;
        padding: 0px 0px;
    }

    h2{
        color: #707070;
        font-size: 12px;
        text-transform: capitalize;

    }

    .down{
        color: #ec6b56;
        transition:  500ms;

        svg{
            transform: rotate(127deg);
            transition:  500ms;
        }
    }

    .up{
        color: #2AC940;
        transition:  500ms;

        svg{
            transform: rotate(45deg);
            transition:  500ms;
        }
    }

    .new{
        color: rgb(253, 157, 2);
    }

    .same{
        color: #339AFF;

        svg{
            transform: rotate(90deg);
            transition:  500ms;
        }
    }

    .past{
        color: #8a8a8a;
        text-transform: lowercase;
    }

    .comparative{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8px;

        svg{
            margin-right: 4px;
        }
    }
}

.ranking-things{
    .value{
        font-size: 16px;
    }
    .comparative{
        margin-top: 0px;
    }
}

.kpi-info{
    flex: 1;
}

.ranking-info{
    padding: 0px 16px;
    min-width: 62px;
    min-height: 51px;
}

.__react_component_tooltip{
    text-transform: lowercase;
}