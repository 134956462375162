.login {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	z-index: 1;
	overflow: hidden;
}

.form-login{
	display: flex;
	flex-direction: column;
	justify-content: center; 
	align-items: center;
	background-color: #fff;
	border-left: 5px solid #2196f3;
	border-radius: 3px;
	height: 300px;
	width: 43%;
	box-shadow: 1px 1px 23px rgba(0,0,0,0.1);
}

.content-login{
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 150px;
	width: 85%;
}

.info-login {
	display: flex;
	flex-direction: column;
	width: 67%;
}

.content-login label{
	width: 92%;
}

.content-login input{
	width: 100%;
}

.img-login{
	width: 120px;
	height: 120px;
}

.img-login img{
	width: 100%;
	height: 100%;
}

.background-login-operator, .background-login-customer {
	top: -60px;
	left: -30px;
	height: 50vh;
	width: 110vw;
	background-position: center;
	background-size: cover;
	position: absolute;
	transform: rotate(-4deg);
	z-index: -1;
	filter: blur(3px)
}

.background-login-operator{
	background-image: url('../../img/background.jpg');
}

.label-userType{
	z-index: 1;
	top: 100px;
	left: 30px;
	position: fixed;
	font-size: 10em;
	color: #FFF;
	opacity: 0.3;
}

.box-login {
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 8px 0px;
	flex-direction: column;
	align-items: center
}

.divBtn-login {
	background-color: #2196f3;
    cursor: pointer;
	color: #FFF;
	border-radius: 9px;
	width: 132px;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	margin-top: 20px;
	height: 32px;
	margin: 5px;
	display: flex;
	user-select: none;
}

.divBtn-login-blocked {
    cursor: pointer;
	color: #FFF;
	border-radius: 9px;
	width: 132px;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	margin-top: 20px;
	height: 32px;
	margin: 5px;
	display: flex;
	user-select: none;
	background-color: rgb(173, 173, 173)
}

.divBtn-login:active{
	background-color: #2378be
}

.divBtn-login a, .divBtn-signup a {
	text-decoration: none;
	margin-top: 8px
}

.divBtn-signup a:visited {
	text-decoration: none;
	color: #2196f3;
}

.divBtn-signup {
    cursor: pointer;
	color: #2196f3;
	align-items: center;
	display: flex;
	user-select: none;
}

.divBtn-login-operator{
    cursor: pointer;
	color: #FFF;
	font-weight: bold;
	text-transform: uppercase;
	margin: 5px;
	position: fixed;
	top: 8px;
	right: 8px
}

.divBtn-login-operator:hover{
	cursor: pointer;
	color: #FFF;
	transform: scale(1.1)
}

.divBtn-login-customer{
    cursor: pointer;
	color: #FFF;
	font-weight: bold;
	text-transform: uppercase;
	margin: 5px;
	position: fixed;
	top: 8px;
	right: 8px
}

.divBtn-login-customer:hover{
	cursor: pointer;
	color: #FFF;
	transform: scale(1.1)
}

.btn-login .link-login{
	color:white;
	text-decoration: none;
}

.div-error{
	height: 24px;
}

.error-user{
	display: none;
	color: #ff0000;
	text-align: center;
	font-size: 16px;
	padding: 3px;
	
}

.teste {
	display: block;
}


@media (max-width: 1000px) {
	.form-login{
		width: 75%;
	}
}

@media (max-width: 500px) {
	.form-login{
		width: 90%;
		height: auto;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.content-login {
		height: auto;
		width: 100%;
		flex-direction: column;
	}

	.info-login {
		width: 80%;
	}
}