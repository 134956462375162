.piechartContainer{
	display: flex;
	margin: 4px;
	border-radius: 3px;	
	box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.3);
	background-color: #FFF;
	flex-direction: column;
	padding-bottom: 16px;
	box-sizing: border-box;

	.info{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
}

.pieChartTitle h1{
	font-weight: 700;
	padding-top: 15px;
	padding-bottom: 16px;
	padding-left: 16px;
	border-bottom: solid 1px #f2f2f2;
}

.piechartContent{
	position: relative;
	flex: 1;
	padding: 16px 32px;
	justify-content: center;
	align-items: center;
	display: flex;
	align-self: center;

	.info{
		position: absolute;
	}

	.value{
		font-size: 26px;
		font-weight: 700;
	}

	.value-description{
		color: #ccc;
	}
}

