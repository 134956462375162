.card-profile {
	box-shadow: 2px 2px 11px rgba(171, 174, 185, 0.3);
   background-color: #fff;
   padding: 8px;
   border-radius: 3px;
   margin: 4px;
   margin-top: 49px;
}

.card-profile-content{
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 8px
}

.card-img {
	align-self: center;
	margin-top: -57px;
	margin-bottom: 8px;
	position: relative;
}

.card-img img {
	width: 100%;
	height: 100%;
	object-fit: cover
}

.card-img svg{
	background-color: #2196f3;
	padding: 4px;
	border-radius: 50%;
	position: absolute;
	top: 60px;
	right: 4px;
}

.card-nameProfile{
	font-weight: bold;
	text-align: center;
	padding: 8px;
}

.status-item {
	font-size: 0.9em;
    color: #a5a5a5;
	text-transform: capitalize;
	padding: 8px
}

.card-subtitle {
	font-size: 0.9em;
    color: #a5a5a5;
	padding: 8px
}

.status-view{
	max-width: 12px;
	max-height: 12px;
	min-width: 12px;
	min-height: 12px;
	position: absolute;
	top: 0px;
	left: 0px;
	border-radius: 100%
}

.status-view.PUBLISHED{
	background-color: #2ac940;
    box-shadow: 0px 0px 5px #2ac940
}

.status-view.PENDING{
	background-color: #ffba01;
    box-shadow: 0px 0px 5px #ffba01
}

.status-view.defasada, .status-view.DEPRECATED, .status-view.EXPIRED{
	background-color: #ff5f58;
    box-shadow: 0px 0px 5px #ff5f58
}

.status-view.expirada{
	background-color: #CCCCCC;
}